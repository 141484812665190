
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Base_url } from '../api_url';
import ActivityItem from '../components/ActivityItem';
import Loading from '../components/Loading';
import Pagination from '../components/Pagination';

function Activity() {
  const { i18n,t } = useTranslation();
  const itemsPerPage = 9; // Количество элементов на страницу
  const [currentPage, setCurrentPage] = useState(1);
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Выполнение GET-запроса к API
  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await fetch(`${Base_url}${i18n.language}/activities`);
        if (!response.ok) {
          throw new Error('Ошибка при загрузке данных');
        }
        const data = await response.json();
        setActivities(data.activities);
        setTimeout(() => {
          setLoading(false);
          // console.log('anar');
        }, 1000); // Задержка в 1 секунду
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchActivities();
  }, [i18n.language]);

  // Если данные загружаются или произошла ошибка
  if (loading) {
    return <Loading></Loading>;
  }

  if (error) {
    return <p>Ошибка: {error}</p>;
  }

  // Рассчитываем, какие элементы отображать на текущей странице
  const totalPages = Math.ceil(activities?.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = activities?.slice(startIndex, startIndex + itemsPerPage);

  return (
    <>
              <Helmet>
        <title>SETKAYA MMC | FƏALIYYƏT</title>
        <meta name="description" content='Süni hasarlar, setkalar və futbol meydançaları üçün döşəmə örtüklərinin istehsalı və quraşdırılması sahəsində geniş təcrübəyə sahibik.' />
        <meta property="og:title" content='SETKAYA MMC | FƏALIYYƏT' />
        <meta property="og:description" content='Süni hasarlar, setkalar və futbol meydançaları üçün döşəmə örtüklərinin istehsalı və quraşdırılması sahəsində geniş təcrübəyə sahibik.' />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content='SETKAYA MMC | FƏALIYYƏT' />
        <meta name="twitter:description" content='Süni hasarlar, setkalar və futbol meydançaları üçün döşəmə örtüklərinin istehsalı və quraşdırılması sahəsində geniş təcrübəyə sahibik.' />
      </Helmet>
    <section className='container activity'>
      <h3>{t("ensonfealiyetler")}</h3>
      <div className='activity-list'>
        {currentItems?.map((item) => (
          <ActivityItem
            key={item.id}
            name={item.name}
            date={item.created_at.slice(0,10)}
            time={item.created_at.slice(11,16)}
            image={item.image?.path}
          />
        ))}
      </div>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </section>
    </>
  );
}

export default Activity;
