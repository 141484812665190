

import React from 'react';

function AddfaqItem({ product, onChange }) {
    return (
        <>
            <div className='form-box'>
                <label className='form-item'>
                    Başlıq[az]
                    <input
                        placeholder='Başlıq daxil edin'
                        value={product.title}
                        onChange={(e) => onChange('title', e.target.value)}
                    />
                </label>
                <label className='form-item'>
                    Dəyər[az]
                    <input
                        placeholder='Dəyər daxil edin'
                        value={product.description}
                        onChange={(e) => onChange('description', e.target.value)}
                    />
                </label>
            </div>
            <div className='form-box'>
                <label className='form-item'>
                    Başlıq[ru]
                    <input
                        placeholder='Введите заголовок на русском'
                        value={product.ruTitle}
                        onChange={(e) => onChange('ruTitle', e.target.value)}
                    />
                </label>
                <label className='form-item'>
                    Dəyər[ru]
                    <input
                        placeholder='Введите описание на русском'
                        value={product.ruDescription}
                        onChange={(e) => onChange('ruDescription', e.target.value)}
                    />
                </label>
            </div>
        </>
    );
}

export default AddfaqItem;