

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Base_url } from '../api_url';
import FilterItem from './FilterItem';


function ServicesFilter({setLoading,setSearchParams,categoryId}) {
  const [filterVisible, setFilterVisible] = useState(true);
  const [categories, setCategories] = useState([]); // Состояние для хранения категорий
  const [error, setError] = useState(null);
  const { i18n,t } = useTranslation();
  const fetchCategories = useCallback(async () => {
    try {
      const response = await fetch(`${Base_url}${i18n.language}/categories`);
      const data = await response.json();
  
      if (data.status) {
        setCategories(data.categories);
      } else {
        throw new Error('Ошибка получения категорий');
      }
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }, [i18n.language, setLoading]); // Добавляем setLoading в зависимости
  
  
  // Вызов функции fetchCategories при монтировании компонента
  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]); // Добавляем fetchCategories в зависимости



  if (error) {
    return <p></p>;
  }

  return (
    <div className='services-filter'>
      <div onClick={() => setFilterVisible(!filterVisible)} className={`filter-top ${filterVisible ? 'filter-active' : ''}`}>
        <span>{t('XIDMƏTLƏR')}</span>
        {filterVisible ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-dash" viewBox="0 0 16 16">
            <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8" />
          </svg>
        )}
      </div>
      {!filterVisible && (
        <div className='bottoms'>
          {/* Маппинг категорий из API */}
          {categories.map((category, index) => (
            <FilterItem
            key={index}
            categoryId={categoryId}
            setSearchParams={setSearchParams}
              item={category}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default ServicesFilter;
