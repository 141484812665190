import React, { useState } from 'react'
import Nav from '../components/Nav'
import { Outlet } from 'react-router-dom'
import Footer from '../components/Footer'

import MediaMenu from '../components/MediaMenu'
import MediaFilter from '../components/MediaFilter'

function Layout() {
    const [showMenu, setShowMenu] = useState(false)
    const [showMediaFilter, setShowMediaFilter] = useState(false)
    return (
        <>
            <Nav setShowMenu={setShowMenu} showMenu={showMenu} setShowMediaFilter={setShowMediaFilter} showMediaFilter={showMediaFilter} />
            <div className='main'>
                <MediaFilter setShowMediaFilter={setShowMediaFilter} showMediaFilter={showMediaFilter} />
                <Outlet />
                <MediaMenu setShowMenu={setShowMenu} showMenu={showMenu} />
            </div>
            <Footer />
         
        </>
    )
}

export default Layout