

function Modal({ show, onClose, categoryNameAz, setCategoryNameAz, categoryNameRu, setCategoryNameRu, handleUpdateCategory }) {
    if (!show) return null;
  return (
    <>
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>Kateqoriyanı yeniləyin</h3>
        <label className='form-item'>
          Category Name [az]
          <input
            type="text"
            value={categoryNameAz}
            onChange={(e) => setCategoryNameAz(e.target.value)}
            placeholder="Category Name in Azerbaijani"
          />
        </label>
        <label className='form-item'>
          Category Name [ru]
          <input
            type="text"
            value={categoryNameRu}
            onChange={(e) => setCategoryNameRu(e.target.value)}
            placeholder="Category Name in Russian"
          />
        </label>
        <div className='categoryButtons'>
        <button className='btn' onClick={handleUpdateCategory}>Yeniləyin</button>
        <button className='btn-red' onClick={onClose}>Bagla</button>

        </div>
      </div>
    </div>
    </>
  )
}

export default Modal