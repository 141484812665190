import React from 'react';
import { Link } from 'react-router-dom';
import { img_url } from '../api_url';

function ServiceItem({ item }) {
  const coverImage = item.images?.find(image => image.is_cover);

  return (
    <Link to={`/service-info/${item.slug}`} className='service-item box'>
      <div className='image'>
        {
          // Если найдено coverImage, отображаем его
          coverImage ? (
            <img height='100%' width='100%' src={`${img_url}${coverImage.path}`} alt={coverImage.alt || item.title} />
          ) : (
            '' // Выводим сообщение, если нет обложки
          )
        }
      </div>
      <div className='text'>
        <p>{item.title}</p>
      </div>
    </Link>
  );
}

export default ServiceItem;
