import React, { useEffect, useState } from 'react';
import KisaMelumat from '../components/KisaMelumat';
import Teklifler from '../components/Teklifler';
import Zemanet from '../components/Zemanet';
import Fealiyyetler from '../components/Fealiyyetler';
import axios from 'axios';
import Loading from '../components/Loading';
import { Base_url } from '../api_url';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

function Home() {
  const [loading, setLoading] = useState(true); // Состояние для загрузки
  const [error, setError] = useState(null); // Состояние для ошибок
  const [banerTopBaslig,setBanerTopBaslig]=useState('')
  const [banerTopDeyer,setBanerTopDeyer]=useState('')
  const [banerButtonDeyer,setBanerButtonDeyer]=useState('')
  const [PartnershipBaslig,setPartnershipBaslig]=useState('')
  const [PartnershipDeyer,setPartnershipDeyer]=useState('')
  const [banner_images,setbanner_images]=useState([])
  const [partnership_images,setpartnership_images]=useState([])
  const [advantage_image,setadvantage_image]=useState([])
  const [banerButtonCategory,setbanerButtonCategory]=useState([])
  const [advantageCategory,setadvantageCategory]=useState([])
  const { i18n } = useTranslation();

  useEffect(() => {
    // Выполнение GET-запроса
    axios.get(`${Base_url}${i18n.language}/homepage`)
      .then((response) => {
        if (response.data.status) {
          setBanerTopBaslig(response.data.homepage.info.banerTopBaslig)
          setBanerTopDeyer(response.data.homepage.info.banerTopDeyer)
          setBanerButtonDeyer(response.data.homepage.info.banerButtonBaslig)
          setbanerButtonCategory(response.data.homepage.info.banerButtonCategory)
          setPartnershipBaslig(response.data.homepage.info.PartnershipBaslig)
          setPartnershipDeyer(response.data.homepage.info.PartnershipDeyer)
          setadvantageCategory(response.data.homepage.info.advantageCategory)
          setbanner_images(response.data.homepage.banner_images)
          setpartnership_images(response.data.homepage.partnership_images)
          setadvantage_image(response.data.homepage.advantage_image)
          
        }
        setTimeout(() => {
          setLoading(false);
        }, 1000); // Задержка в 1 секунду
        // setLoading(false); // Убираем состояние загрузки
      })
      .catch((error) => {
        console.error('Ошибка при получении данных о нас', error);
        setError('Ошибка при получении данных.');
        setLoading(false);
      });
  }, [i18n.language]);

  if (loading) {
    return <Loading></Loading>;
  }

  if (error) {
    return <p>{error}</p>;
  }
// console.log(banerTopBaslig,banerTopDeyer,banner_images);
  return (
    <>
      <Helmet>
        <title>SETKAYA MMC</title>
        <meta name="description" content='SETKAYA MMC süni yaşıl, ot, yarpaq hasarlar, setkalar və süni döşəmə örtükləri sahəsində ixtisaslaşmışdır. Mülkləriniz üçün estetik və funksional həllər təqdim edirik.' />
        <meta property="og:title" content='SETKAYA MMC' />
        <meta property="og:description" content='SETKAYA MMC süni yaşıl, ot, yarpaq hasarlar, setkalar və süni döşəmə örtükləri sahəsində ixtisaslaşmışdır. Mülkləriniz üçün estetik və funksional həllər təqdim edirik.' />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content='SETKAYA MMC' />
        <meta name="twitter:description" content='SETKAYA MMC süni yaşıl, ot, yarpaq hasarlar, setkalar və süni döşəmə örtükləri sahəsində ixtisaslaşmışdır. Mülkləriniz üçün estetik və funksional həllər təqdim edirik.' />
      </Helmet>
      <KisaMelumat 
      banerTopBaslig={banerTopBaslig}
      banerTopDeyer={banerTopDeyer}
      banerButtonDeyer={banerButtonDeyer}
      banner_images={banner_images}
      banerButtonCategory={banerButtonCategory}
       />
      <Teklifler
      PartnershipBaslig={PartnershipBaslig}
      PartnershipDeyer={PartnershipDeyer}
      partnership_images={partnership_images}
      />
      <Zemanet advantage_image={advantage_image} advantageCategory={advantageCategory}/>
      <Fealiyyetler />
    </>
  )
}

export default Home