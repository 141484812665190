import React from 'react'
import { img_url } from '../api_url';
function KisaMelumatSlide({ slide, isVisible,banerTopBaslig,banerTopDeyer}) {
    return (
        <div className={`slide ${isVisible ? 'visible' : 'hidden'}`}>
            <div className='bg-image'  style={{ backgroundImage: `url(${img_url}${slide.path})` }}></div>
            <div className='text'>
                <h1>{banerTopBaslig}</h1>
                <p>{banerTopDeyer}</p>
            </div>
        </div>
    )
}

export default KisaMelumatSlide