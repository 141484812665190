import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { img_url } from '../api_url'



function Teklifler({PartnershipBaslig,PartnershipDeyer,partnership_images}) {
  const [showText, setShowText] = useState(false)
  const { t } = useTranslation();
  // console.log(partnership_images.slice(-1));
  return (
    <section className='container container_fealiyetler box teklifler'>
      <div className='left'>
        <h1>{PartnershipBaslig}</h1>
        <p className={`${showText ? 'show-text' : ''}`}>{PartnershipDeyer}</p>
        <span onClick={()=>setShowText(!showText)}>{showText ? t('dahacaz') : t('dahacox')}</span>
        <div className='photo-list'>
          {
            partnership_images?.slice(0,-1).map((item,index)=>(
              <img key={index} src={`${img_url}${item.path}`} alt={item.title}></img>
            ))
          }
        </div>
      </div>
      <div className='right'>
      {
            partnership_images?.slice(-1).map((item,index)=>(
              <img key={index} src={`${img_url}${item.path}`} alt={item.title}></img>
            ))
          }
      
      </div>
    </section>
  )
}

export default Teklifler