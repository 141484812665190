import React from 'react'
import { useTranslation } from 'react-i18next';
import { img_url } from '../api_url';
function ActivityItem({name,date,image,time}) {
    const { t } = useTranslation();
    
    return (
        <div className='box activity-item'>
            <img height='100%' width='100%' alt={name} src={`${img_url}${image}`} />
            <div className='ust'>
                <div className='bg-blur'></div>
                <p>{name}</p>
                <p>{t('tarix')} : {date} - {time}</p>
            </div>
        </div>
    )
}

export default ActivityItem