import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Base_url } from '../api_url';
import FilterItem from './FilterItem';

function MediaFilter({ setShowMediaFilter, showMediaFilter }) {
  const [filterVisible, setFilterVisible] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryId = searchParams.get('category'); // Получаем категорию из URL
  const [categories, setCategories] = useState([]); // Состояние для хранения категорий
  const { i18n } = useTranslation();

  // Мемоизация функции fetchCategories с помощью useCallback
  const fetchCategories = useCallback(async () => {
    try {
      const response = await fetch(`${Base_url}${i18n.language}/categories`);
      const data = await response.json();

      if (data.status) {
        setCategories(data.categories);
      } else {
        throw new Error('Ошибка получения категорий');
      }
    } catch (error) {
      console.error('Ошибка:', error);
    }
  }, [i18n.language]); // Добавляем i18n.language в зависимости

  // Вызов функции fetchCategories при монтировании компонента
  useEffect(() => {
    fetchCategories();
    setFilterVisible(true);
  }, [fetchCategories]); // Добавляем fetchCategories в зависимости

  return (
    <div className={`mobile-filter ${showMediaFilter ? 'mobile-filter-activ' : ''}`}>
      <div className='mobile-swip-item'>
        {filterVisible && (
          <div className='bottoms'>
            {categories.map((category, index) => (
              <FilterItem
                key={index}
                setShowMediaFilter={setShowMediaFilter}
                categoryId={categoryId}
                setSearchParams={setSearchParams}
                item={category}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default MediaFilter;
