import React from 'react'
import userPinIcon from '../image/icons/bx-user-pin.png';
import targetLockIcon from '../image/icons/bx-target-lock.png';
import packageIcon from '../image/icons/bx-package.png';
import loaderCircleIcon from '../image/icons/bx-loader-circle.png';
import { img_url } from '../api_url';


function Zemanet({advantage_image,advantageCategory}) {
    return (
        <section className='container box zemanet'>
            {
                advantage_image?.map(item=>(
                     <div key={item.id} className='left'>
                <img height='100%' width='100%' src={`${img_url}${item.path}`}  alt='advantage Sekill'/>
            </div>
                ))
            }
           
            <div className='right'>
                {
                    advantageCategory?.map((item,index)=>(
                    <div key={index} className='item'>
                        
                    <img src={index ===0? packageIcon:index ===1?loaderCircleIcon:index===2?userPinIcon:targetLockIcon} alt='' />
                    <h2>{item.baslig}</h2>
                    <p>{item.deyer}</p>
                </div>
                    ))
                }
              
  
            </div>
        </section>
    )
}

export default Zemanet