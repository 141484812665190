import React from 'react'
import logo from '../image/setkaya-logo.png'

import digigoLogo from '../image/digigo-logo.png'
import { Link } from 'react-router-dom'


function AdminNav({data}) {
    return (
        <nav className='admin-nav'>
            <div className='logo'>
                <Link to='/'>
                    <img src={logo} alt={'setkaya logo'} />
                </Link>
            </div>
            <div className='right'>
                <span>{data.email}</span>
                
                <img src={digigoLogo} alt={'Digigo logo'}/>
            </div>
        </nav>
    )
}

export default AdminNav