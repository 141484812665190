import React, { useCallback, useEffect, useState } from 'react'
import KisaMelumatSlide from './KisaMelumatSlide';


function KisaMelumatsetInterval({banerTopBaslig,banerTopDeyer,banerButtonDeyer,banerButtonCategory,banner_images}) {
   
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = useCallback(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % banner_images.length);
      }, [banner_images.length]); // Мемоизируем функцию с зависимостью от длины массива изображений
    
      useEffect(() => {
        const intervalId = setInterval(nextSlide, 2500); // Меняем слайд каждые 2.5 секунды
        return () => clearInterval(intervalId); // Очищаем интервал при размонтировании компонента
      }, [nextSlide]); // Добавляем nextSlide в зависимости
    
      const handleSlideClick = () => {
        nextSlide();
      };
    return (
        <section className='container box kisa-melumat'>
            <div className='picture'
                onClick={handleSlideClick}
            >

                {banner_images?.map((slide, index) => (
                    <KisaMelumatSlide
                        key={slide.id}
                        slide={slide}
                        banerTopBaslig={banerTopBaslig}
                        banerTopDeyer={banerTopDeyer}
                        isVisible={index === currentIndex} />
                ))}
            </div>
            <div className='info'>
                <h3>{banerButtonDeyer}</h3>
                <div className='right'>
                    {
                        banerButtonCategory?.map(item=>(
                                 <>
                    {/* <hr/> */}
                                   <div key={item.id} className='item'>
                        <h2>{item.baslig}</h2>
                        <p>{item.deyer}</p>
                    </div>
                                 </>
                        ))
                    }
             
               
                </div>
            </div>
        </section>
    )
}

export default KisaMelumatsetInterval