import React from 'react'
import { useTranslation } from 'react-i18next';

function FaqItem({item,isVisible, setFaqVisible, index }) {
  const { i18n } = useTranslation();
  return (
    <div className={`faq-item ${isVisible ? 'faq-item-active' : ''}`}>
      <div className={`left ${isVisible ? 'faq-visible' : ''}`}>
        {
          i18n.language ==='az'? (<><h4>{item.title}</h4><p>{item.description}</p></>):(<><h4>{item.translations.ru.title}</h4><p>{item.translations.ru.description}</p></>)
        }
        
        
      </div>
      <div className={`faq-btn ${isVisible ? 'faq-btn-active' : ''}`} onClick={() => setFaqVisible(isVisible ? 0 : index)}>
        {isVisible ? (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
        </svg>) : (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
        </svg>)}


      </div>
    </div>
  )
}

export default FaqItem