import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';

function Languages() {
    const langRef = useRef(null);
    const { i18n } = useTranslation();
    const languages = [
      { code: 'ru', lang: 'Ru' },
      { code: 'az', lang: 'Az' },
    ];
    const [lang, setLang] = useState(i18n.language);
    const [langActive, setLangActive] = useState(false);
    const langCategories = languages.filter((item) => item.lang !== lang);

    const changeLanguage = (item) => {
      i18n.changeLanguage(item.code);
      setLang(item.lang);
      setLangActive(false);
    };
  
    useEffect(() => {
      const savedLanguage = localStorage.getItem('i18nextLng');
      if (savedLanguage) {
        i18n.changeLanguage(savedLanguage);
        setLang(savedLanguage.charAt(0).toUpperCase() + savedLanguage.slice(1).toLowerCase());
      }
    }, [i18n]);
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (langRef.current && !langRef.current.contains(event.target)) {
          setLangActive(false);
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [langRef]);
  return (
  <>
      <div className="lang" ref={langRef} onClick={() => setLangActive(!langActive)}>
            <p>
              {lang}
              <i className={`fa-solid fa-angle-down ${langActive ? 'rotate' : ''}`}></i>
            </p>
            {langActive && (
              <ul>
                {langCategories.map((item, id) => (
                  <li className="nav-dropdown-item" key={id} onClick={() => changeLanguage(item)}>
                    {item.lang}
                  </li>
                ))}
              </ul>
            )}
          </div>
  </>
  )
}

export default Languages