

import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { Base_url } from '../api_url';

function EditFooter() {
  
  const [sloganRu,setsloganRu]=useState('')
  const [addressRu,setaddressRu]=useState('')
  const [formData, setFormData] = useState({
    slogan: '',
    address: '',
    phone: '',
    email: '',
   
    socialLinks: {
      facebook: '',
      whatsapp: '',
      instagram: '',
      tiktok: ''
    }
  });
  const [isUpdate, setIsUpdate] = useState(false);

  // Выполняем GET-запрос при загрузке компонента для проверки наличия настроек
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(`${Base_url}az/settings`);
        if (response.data.status === true) {
          const settings = response.data.settings;
          const contacts = settings.contacts;
          const socialLinks = settings.social_links;
          setFormData({
            slogan: settings.slogan || '',
            // sloganRu: settings.translations?.ru?.slogan || '',
            address: settings.address || '',
            // addressRu: settings.translations?.ru?.address || '',
            phone: contacts[0]?.phone || '',
            email: contacts[0]?.email || '',
            socialLinks: {
              facebook: socialLinks[0]?.facebook || '',
              whatsapp: socialLinks[0]?.whatsapp || '',
              instagram: socialLinks[0]?.instagram || '',
              tiktok: socialLinks[0]?.tiktok || ''
            }
          });
          setIsUpdate(true); // Устанавливаем, что мы будем делать обновление
        }
      } catch (error) {
      }
    };

    fetchSettings();
  }, []);
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(`${Base_url}ru/settings`);
        if (response.data.status === true) {
          const settings = response.data.settings;
          setsloganRu(settings.slogan || '')
          setaddressRu(settings.address || '')
       
        }
      } catch (error) {
      }
    };

    fetchSettings();
  }, []);

  // Обработка изменений в полях формы
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name in formData.socialLinks) {
      setFormData({
        ...formData,
        socialLinks: {
          ...formData.socialLinks,
          [name]: value
        }
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  // Обработка отправки формы
  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = Cookies.get('token_adminPage');

    // Преобразуем данные для отправки в формате массивов
    const requestData = {
      slogan: formData.slogan,
      address: formData.address,
      contacts: [{ phone: formData.phone, email: formData.email }], // Массив для контактов
      social_links: [{ // Массив для соцсетей
        facebook: formData.socialLinks.facebook,
        whatsapp: formData.socialLinks.whatsapp,
        instagram: formData.socialLinks.instagram,
        tiktok: formData.socialLinks.tiktok
      }],
      translations: {
        ru: {
          slogan: sloganRu,
          address: addressRu
        }
      }
    };

    try {
      if (isUpdate) {
        // Если запись существует, выполняем обновление
        await axios.post(`${Base_url}admin/settings/update`, requestData, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        alert('Настройки успешно обновлены');
      } else {
        // Если записи нет, выполняем создание
        await axios.post(`${Base_url}admin/settings/create`, requestData, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        alert('Настройки успешно созданы');
      }
    } catch (error) {
      alert('Произошла ошибка при сохранении настроек');
    }
  };

  return (
    <form className='edit-footer right-content' onSubmit={handleSubmit}>
      <div className='box container-1'>
        <div className='top'>
          <div className='w-40'>
            <label className='form-item'>
              Başlıq [az]
              <input 
                   placeholder='Başlıq daxil edin'
                   name="slogan"
                value={formData.slogan}
                onChange={handleChange}
                required
                />
             
            </label>
            <label className='form-item'>
              Başlıq [ru]
              <input 
                   placeholder='Başlıq [ru] daxil edin'
                   name="sloganRu"
                   value={sloganRu}
                   onChange={(e)=> setsloganRu(e.target.value)}
                required
                />
            
            </label>
          </div>
          <div className='w-60'>
            <div className='form-box'>
              <label className='form-item'>
                Facebook
                <input 
                  placeholder='URL daxil edin'
                  name="facebook"
                  value={formData.socialLinks.facebook}
                  onChange={handleChange}
                />
              </label>
              <label className='form-item'>
                Whatsapp
                <input 
                  placeholder='994502223311 bu formatda qeyd edin.'
                  name="whatsapp"
                  value={formData.socialLinks.whatsapp}
                  onChange={handleChange}
                />
              </label>
            </div>
            <div className='form-box'>
              <label className='form-item'>
                Instagram
                <input 
                  placeholder='URL daxil edin'
                  name="instagram"
                  value={formData.socialLinks.instagram}
                  onChange={handleChange}
                />
              </label>
              <label className='form-item'>
                Tiktok
                <input 
                  placeholder='URL daxil edin'
                  name="tiktok"
                  value={formData.socialLinks.tiktok}
                  onChange={handleChange}
                />
              </label>
            </div>
          </div>
        </div>
        <div className='bottom'>
          <div className='form-box'>
            <label className='form-item'>
              Əlaqə
              <input
              required
                placeholder='Mobil nömrə daxil edin'
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </label>
            <label className='form-item'>
              E-poçt
              <input
              required
                placeholder='E-poçt daxil edin'
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </label>
          </div>
          <div className='form-box'>
            <label className='form-item'>
              Ünvan [az]
              <input
              required 
                placeholder='Ünvan daxil edin'
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
            </label>
            <label className='form-item'>
              Ünvan [ru]
              <input
              required 
                placeholder='Ünvan [ru] daxil edin'
                name="addressRu"
                value={addressRu}
                onChange={(e)=> setaddressRu(e.target.value)}
              />
            </label>
          </div>
        </div>
      </div>
      <button className='btn' type="submit">Yadda saxlayın</button>
    </form>
  );
}

export default EditFooter;