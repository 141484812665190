
import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ServicesFilter from '../components/ServicesFilter';
import ServiceItem from '../components/ServiceItem';
import Pagination from '../components/Pagination';
import { useTranslation } from 'react-i18next';
import Loading from '../components/Loading';
import { Base_url } from '../api_url';
import { Helmet } from 'react-helmet';

function Services() {
  const { i18n, t } = useTranslation();
  const itemsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams(); // Используем поисковые параметры

  const categoryId = searchParams.get('category'); // Получаем категорию из URL
  const search = searchParams.get('search'); // Получаем категорию из URL

  // GET запрос к API для получения списка сервисов, с фильтром по категории
  const fetchServices = useCallback(async () => {
    try {
      const response = await fetch(
        `${Base_url}${i18n.language}/services?${search !== null ? `search=${search}` : `category=${categoryId || ''}`}`
      );
      const data = await response.json();
  
      setServices(data.services);
      setCurrentPage(1)
      setTimeout(() => {
        setLoading(false);
      }, 1000); // Задержка в 1 секунду
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }, [categoryId, search, i18n.language]); // Добавляем зависимости
  
  useEffect(() => {
    fetchServices(); // Загружаем сервисы при изменении категории
  }, [fetchServices]); // Добавляем мемоизированную функцию в зависимости



  // Расчёт количества страниц
  const totalPages = Math.ceil(services?.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = services?.slice(startIndex, startIndex + itemsPerPage);

  // Обработка состояний загрузки и ошибок
  if (loading) {
    return <Loading />; // Показываем экран загрузки на 1 секунду
  }

  if (error) {
    return <p>Ошибка: {error}</p>;
  }

  return (
    <>
                  <Helmet>
        <title>SETKAYA MMC | XIDMƏTLƏR</title>
        <meta name="description" content='Panel, yaşıl, ot və yarpaq hasarlar, setkalar və süni döşəmə örtüklərinin istehsalı. İstənilən hündürlükdə hasar və döşəmə örtükləri üçün peşəkar quraşdırma xidməti.' />
        <meta property="og:title" content='SETKAYA MMC | XIDMƏTLƏR' />
        <meta property="og:description" content='Panel, yaşıl, ot və yarpaq hasarlar, setkalar və süni döşəmə örtüklərinin istehsalı. İstənilən hündürlükdə hasar və döşəmə örtükləri üçün peşəkar quraşdırma xidməti.' />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content='SETKAYA MMC | XIDMƏTLƏR' />
        <meta name="twitter:description" content='Panel, yaşıl, ot və yarpaq hasarlar, setkalar və süni döşəmə örtüklərinin istehsalı. İstənilən hündürlükdə hasar və döşəmə örtükləri üçün peşəkar quraşdırma xidməti.' />
      </Helmet>
    <section className='services container'>
      <ServicesFilter setLoading={setLoading} categoryId={categoryId} setSearchParams={setSearchParams} /> {/* Фильтр с передачей setSearchParams */}
      <div className='right'>
        <h3>{t('onəçıxanlar')}</h3>
        <div className='services-list'>
          {
            !services && (
              <div className='serviceEror'>
                <h3>{t("tapilmadi")}</h3>
                <strong>{t('tapilmadiText')}</strong>
              </div>
            )
          }
          {currentItems?.map((service) => (
            <ServiceItem
              key={service.id}
              item={service}
            />
          ))}
        </div>
      </div>
      {
        services?.length > 0 && (<Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />)
      }
    </section>
    </>
  );
}

export default Services;