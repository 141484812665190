

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import AdminNav from '../components/AdminNav';
import Dashboard from '../components/Dashboard';
import EditHomePage from '../components/EditHomePage';
import EditFooter from '../components/EditFooter';
import User from '../components/User';
import AddProduct from '../components/AddProduct';
import AddActivity from '../components/AddActivity';
import EditAbout from '../components/EditAbout';
import AddFaq from '../components/AddFaq';
import FollowActivity from '../components/FollowActivity';
import FollowProduct from '../components/FollowProduct';
import { Base_url } from '../api_url';
import axios from 'axios';

function AdminHome() {
  const admin_id = Cookies.get('Admin_id')
  const visible_id = Cookies.get('visible_id')
  const [pageContent, setPageContent] = useState(+admin_id || 1);
  const [dropdowIsVisible, setDropdowIsVisible] = useState(+admin_id?(+visible_id || 0):1)
  const [data,setData]=useState([])
  const navigate = useNavigate();
  const token = Cookies.get('token_adminPage');
  console.log(typeof +pageContent, dropdowIsVisible,'zaur');
  useEffect(() => {
    // Получаем данные "О нас"
    axios.get(`${Base_url}admin/profile/`,{
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((response) => {
      if (response.data && response.data.data) {
        setData(response.data.data)
        
      }
    })
    .catch((error) => {
      if(error.response && error.response.status === 401){
        Cookies.remove('token_adminPage')
        Cookies.remove('Admin_id')
        Cookies.remove('visible_id')
        navigate('/admin/login')
      }else{
      }
    });
  }, [token,navigate]);
  useEffect(()=>{
    
    Cookies.set('Admin_id', pageContent, { expires: 30 });
    Cookies.set('visible_id', dropdowIsVisible, { expires: 30 });
},[pageContent,dropdowIsVisible])
  useEffect(() => {
    const token = Cookies.get('token_adminPage');

    // Если токен отсутствует, перенаправляем на главную страницу
    if (!token) {
      navigate('/admin/login'); // Перенаправляем на страницу логина или главную страницу
    }
  }, [navigate]);

  let content;

  switch (pageContent) {
    case 1:
      content = <EditHomePage />;
      break;
    case 2:
      content = <EditFooter />;
      break;
    case 3:
      content = <AddProduct />;
      break;
    case 4:
      content = <FollowProduct />;
      break;
    case 5:
      content = <AddActivity />;
      break;
    case 6:
      content = <FollowActivity />;
      break;
    case 7:
      content = <User />;
      break;
    case 8:
      content = <EditAbout />;
      break;
    case 9:
      content = <AddFaq />;
      break;
    default:
      content = <EditHomePage />;
      break;
  }
  return (
    <>
      <AdminNav data={data}/>
      <div className='bottom-page'>
        <Dashboard setDropdowIsVisible={setDropdowIsVisible} dropdowIsVisible={dropdowIsVisible} pageContent={pageContent} setPageContent={setPageContent} />
        {content}
      </div>
    </>
  );
}

export default AdminHome;
