





import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import userPinIcon from '../image/icons/bx-user-pin.png';
import targetLockIcon from '../image/icons/bx-target-lock.png';
import packageIcon from '../image/icons/bx-package.png';
import loaderCircleIcon from '../image/icons/bx-loader-circle.png';
import { Base_url } from '../api_url';
function EditHomePage() {
  const [info, setInfo] = useState({
    banerTopBaslig: '',
    banerTopDeyer: '',
    banerButtonBaslig: '',
    banerButtonCategory: [{ baslig: '', deyer: '' }],
    PartnershipBaslig: '',
    PartnershipDeyer: '',
    advantageCategory: [{ baslig: '', deyer: '' }],

  });

  const [bannerImages, setBannerImages] = useState([]);
  const [partnershipImages, setPartnershipImages] = useState([]);
  const [advantageImage, setAdvantageImage] = useState(null);
  const [isExistingHomepage, setIsExistingHomepage] = useState(false); // Check if homepage exists
const [banerTopDeyerRu,setbanerTopDeyerRu]=useState('')
const [banerTopBasligRu,setbanerTopBasligRu]=useState('')
const [banerButtonBasligRu,setbanerButtonBasligRu]=useState('')
const [PartnershipBasligRu,setPartnershipBasligRu]=useState('')
const [PartnershipDeyerRu,setPartnershipDeyerRu]=useState('')
const [banerButtonCategoryRu,setbanerButtonCategoryRu]=useState([{ baslig: '', deyer: '' }])
const [advantageCategoryRu,setadvantageCategoryRu]=useState([{ baslig: '', deyer: '' }])
  const token = Cookies.get('token_adminPage');
  useEffect(() => {
    // Fetch existing homepage data
    const fetchHomepageData = async () => {
      try {
        const response = await axios.get(`${Base_url}ru/homepage`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data && response.data.homepage) {
          const homepageData = response.data.homepage;
          setbanerTopBasligRu(homepageData.info.banerTopBaslig)
          setbanerTopDeyerRu(homepageData.info.banerTopDeyer)
          setbanerButtonBasligRu(homepageData.info.banerButtonBaslig)
          setPartnershipBasligRu(homepageData.info.PartnershipBaslig)
          setPartnershipDeyerRu(homepageData.info.PartnershipDeyer)
          setbanerButtonCategoryRu(homepageData.info.banerButtonCategory)
          setadvantageCategoryRu(homepageData.info.advantageCategory)
        }
      } catch (error) {
        console.error('Error fetching homepage data:', error);
      }
    };

    fetchHomepageData();
  }, [token]);
  useEffect(() => {
    // Fetch existing homepage data
    const fetchHomepageData = async () => {
      try {
        const response = await axios.get(`${Base_url}az/homepage`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data && response.data.homepage) {
          const homepageData = response.data.homepage;
          setInfo(homepageData.info || {});
          setBannerImages(homepageData.banner_images || []);
          setPartnershipImages(homepageData.partnership_images || []);
          setAdvantageImage(homepageData.advantage_image[0] || null);
          setIsExistingHomepage(true); // Set homepage as existing
        }
      } catch (error) {
        console.error('Error fetching homepage data:', error);
      }
    };

    fetchHomepageData();
  }, [token]);

  const handleInfoChange = (key, value) => {
    setInfo((prev) => ({ ...prev, [key]: value }));
  };

  const handleCategoryChange = (index, key, value) => {
    setInfo((prev) => {
      const updatedCategories = [...prev.banerButtonCategory];
      updatedCategories[index][key] = value; // Update the correct key ('baslig' or 'deyer') for the right index
      return { ...prev, banerButtonCategory: updatedCategories };
    });
  };
  const handleCategoryChangeru = (index, key, value) => {
    setbanerButtonCategoryRu((prev) => {
      const updatedCategories = [...prev];
      updatedCategories[index][key] = value; // Update the correct key ('baslig' or 'deyer')
      return updatedCategories; // Return the updated array
    });
  };

  const handleAddBannerCategory = () => {
    setInfo((prev) => ({
      ...prev,
      banerButtonCategory: [...prev.banerButtonCategory, { baslig: '', deyer: '' }],
    }));
  };
  const handleAddBannerCategoryru = () => {
    setbanerButtonCategoryRu((prev) => [
      ...prev, 
      { baslig: '', deyer: '' } // Add a new category
    ]);
  };

  // Function to remove a category
  const handleRemoveBannerCategory = (index) => {
    setInfo((prev) => {
      const updatedCategories = prev.banerButtonCategory.filter((_, i) => i !== index);
      return { ...prev, banerButtonCategory: updatedCategories };
    });
  };
  const handleRemoveBannerCategoryru = (index) => {
    setbanerButtonCategoryRu((prev) => {
      const updatedCategories = prev.filter((_, i) => i !== index); // Remove the item at the given index
      return updatedCategories; // Return the updated array
    });
  };
  // Advantage Category Handlers
  const handleAdvantageCategoryChange = (index, key, value) => {
    const updatedCategories = [...info.advantageCategory];
    updatedCategories[index][key] = value;
    setInfo((prev) => ({ ...prev, advantageCategory: updatedCategories }));
  };
  const handleAdvantageCategoryChangeru = (index, key, value) => {
    setadvantageCategoryRu((prev) => {
      const updatedCategories = [...prev];
      updatedCategories[index][key] = value; // Update the correct key ('baslig' or 'deyer')
      return updatedCategories; // Return the updated array
    });
  };
  const handleAddAdvantageCategory = () => {
    if (info.advantageCategory.length < 4) {
      setInfo((prev) => ({
        ...prev,
        advantageCategory: [...prev.advantageCategory, { baslig: '', deyer: '' }],
      }));
    } else {
      alert('Нельзя добавить больше 5 категорий');
    }
  };
  const handleAddAdvantageCategoryru = () => {
    setadvantageCategoryRu((prev) => [
      ...prev, 
      { baslig: '', deyer: '' } // Add a new category
    ]);
  };
  const handleRemoveAdvantageCategory = (index) => {
    const updatedCategories = info.advantageCategory.filter((_, i) => i !== index);
    setInfo((prev) => ({ ...prev, advantageCategory: updatedCategories }));
  };
  const handleRemoveAdvantageCategoryru = (index) => {
    setadvantageCategoryRu((prev) => {
      const updatedCategories = prev.filter((_, i) => i !== index); // Remove the item at the given index
      return updatedCategories; // Return the updated array
    });
  };
  // Image Handlers
  const handleBannerImageUpload = (file) => {
    setBannerImages((prev) => [...prev, file]);
  };

  const handlePartnershipImageUpload = (file) => {
    if (partnershipImages.length < 4) {
      setPartnershipImages((prev) => [...prev, file]);
    } else {
      alert('Нельзя добавить больше 4 изображений для партнёров');
    }
  };

  const handleAdvantageImageUpload = (file) => {
    setAdvantageImage(file);
  };

  // Remove image handler for banners, partnership, and advantage images
  const handleRemoveBannerImage = (index) => {
    setBannerImages((prev) => prev.filter((_, i) => i !== index));
  };

  const handleRemovePartnershipImage = (index) => {
    setPartnershipImages((prev) => prev.filter((_, i) => i !== index));
  };

  const handleRemoveAdvantageImage = () => {
    setAdvantageImage(null);
  };

  // Submit handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('info[banerTopBaslig]', info.banerTopBaslig);
    formData.append('info[banerTopDeyer]', info.banerTopDeyer);
    formData.append('info[banerButtonBaslig]', info.banerButtonBaslig);
    info.banerButtonCategory.forEach((category, index) => {
      formData.append(`info[banerButtonCategory][${index}][baslig]`, category.baslig);
      formData.append(`info[banerButtonCategory][${index}][deyer]`, category.deyer);
    });
    formData.append('info[PartnershipBaslig]', info.PartnershipBaslig);
    formData.append('info[PartnershipDeyer]', info.PartnershipDeyer);
    info.advantageCategory.forEach((advantage, index) => {
      formData.append(`info[advantageCategory][${index}][baslig]`, advantage.baslig);
      formData.append(`info[advantageCategory][${index}][deyer]`, advantage.deyer);
    });
    formData.append('translations[ru][info][banerTopBaslig]', banerTopBasligRu);
    formData.append('translations[ru][info][banerTopDeyer]', banerTopDeyerRu);
    formData.append('translations[ru][info][banerButtonBaslig]', banerButtonBasligRu);
    formData.append('translations[ru][info][PartnershipBaslig]', PartnershipBasligRu);
    formData.append('translations[ru][info][PartnershipDeyer]', PartnershipDeyerRu);
    banerButtonCategoryRu.forEach((category, index) => {
      formData.append(`translations[ru][info][banerButtonCategory][${index}][baslig]`, category.baslig);
      formData.append(`translations[ru][info][banerButtonCategory][${index}][deyer]`, category.deyer);
    });
    advantageCategoryRu.forEach((category, index) => {
      formData.append(`translations[ru][info][advantageCategory][${index}][baslig]`, category.baslig);
      formData.append(`translations[ru][info][advantageCategory][${index}][deyer]`, category.deyer);
    });
    


    bannerImages.forEach((image, index) => {
      if (image instanceof File) {
        formData.append(`banner_images[${index}]`, image);
      }
    });

    partnershipImages.forEach((image, index) => {
      if (image instanceof File) {
        formData.append(`partnership_images[${index}]`, image);
      }
    });

    if (advantageImage && advantageImage instanceof File) {
      formData.append('advantage_image', advantageImage);
    }

    try {
      
      if (isExistingHomepage) {
        // Update existing homepage if it exists
        await axios.post(`${Base_url}admin/homepage/update`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        alert('Ugurla Yenilendi')
      } else {
        // Create new homepage if it doesn't exist
        await axios.post(`${Base_url}admin/homepage/create`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        alert('Ugurla Yaradildi');
      }
    } catch (error) {
      console.error('Error updating/creating homepage', error);
    }
  };

  return (
    <form className='right-content edit-home-page' onSubmit={handleSubmit}>
      {/* Banner Information */}
      <div className='box container-1'>
        <div className='bottom w-40'>
          <label className='form-item'>
            Başlıq [az]
            <input required value={info.banerTopBaslig || ''} type="text" placeholder='Başlıq daxil edin' onChange={(e) => handleInfoChange('banerTopBaslig', e.target.value)} />
          </label>
          <label className='form-item'>
            Alt başlıq [az]
            <input required value={info.banerTopDeyer || ''} type="text" placeholder='Alt başlıq daxil edin' onChange={(e) => handleInfoChange('banerTopDeyer', e.target.value)} />
          </label>
          <label className='form-item'>
            Banner düyməsinin başlığı [az]
            <input required value={info.banerButtonBaslig || ''} type="text" onChange={(e) => handleInfoChange('banerButtonBaslig', e.target.value)} />
          </label>
        </div>
        <div className='bottom w-40'>
          <label className='form-item'>
            Başlıq [ru]
            <input required value={banerTopBasligRu || ''} type="text" placeholder='Başlıq daxil edin' onChange={(e) => setbanerTopBasligRu(e.target.value) } />
          </label>
          <label className='form-item'>
            Alt başlıq [ru]
            <input value={banerTopDeyerRu || ''} type="text" placeholder='Alt başlıq daxil edin' onChange={(e) => setbanerTopDeyerRu(e.target.value) } />
          </label>
          <label className='form-item'>
            Banner düyməsinin başlığı [ru]
            <input required value={banerButtonBasligRu || ''} type="text" onChange={(e) =>setbanerButtonBasligRu( e.target.value)} />
          </label>
        </div>

        {/* Banner Categories */}
        <div className='top'>
          <h4>Banner Categories [az]</h4>
          {info?.banerButtonCategory?.map((category, index) => (
            <div key={index}>
             
              <label className='form-item'>
                Kategoriya Başlıq [az]
                <input required type="text" value={category.baslig} onChange={(e) => handleCategoryChange(index, 'baslig', e.target.value)} />
              </label>
              <label className='form-item'>
                Kategoriya Dəyər [az]
                <input required type="text" value={category.deyer} onChange={(e) => handleCategoryChange(index, 'deyer', e.target.value)} />
              </label>
              <button className='btn-red' type="button" onClick={() => handleRemoveBannerCategory(index)}>Kateqoriyanı silin</button>
            </div>
          ))}
          <button className='btn' type="button" onClick={handleAddBannerCategory}>Kateqoriya əlavə edin</button>
        </div>
        <div className='top'>
  <h4>Banner Categories [ru]</h4>
  {banerButtonCategoryRu?.map((category, index) => (
    <div key={index}>
      <label className='form-item'>
        Kategoriya Başlıq [ru]
        <input
          type="text"
          value={category.baslig}
          onChange={(e) => handleCategoryChangeru(index, 'baslig', e.target.value)}
        />
      </label>
      <label className='form-item'>
        Kategoriya Dəyər [ru]
        <input
          type="text"
          value={category.deyer}
          onChange={(e) => handleCategoryChangeru(index, 'deyer', e.target.value)}
        />
      </label>
      <button className='btn-red' type="button" onClick={() => handleRemoveBannerCategoryru(index)}>
        Kateqoriyanı silin
      </button>
    </div>
  ))}
  <button className='btn' type="button" onClick={handleAddBannerCategoryru}>
    Kateqoriya əlavə edin
  </button>
</div>


        {/* Banner Images with Remove Button */}
        <div className='top'>
          <h4>Banner Images</h4>
          {bannerImages?.map((img, index) => (
            <div key={index} className='image-item'>
              <span>{img?.name || 'Uploaded image'}</span>
              <button className='btn-red' type="button" onClick={() => handleRemoveBannerImage(index)}>Şekili silin</button>
            </div>
          ))}
          <input type='file' onChange={(e) => handleBannerImageUpload(e.target.files[0])} />
        </div>
      </div>

      <div className='box container-2'>
        <div className='w-60'>
          <label className='form-item'>
            Partnership baslig [az]
            <input required placeholder='Başlıq daxil edin' value={info.PartnershipBaslig || ''} type="text" onChange={(e) => handleInfoChange('PartnershipBaslig', e.target.value)} />
          </label>
          <label className='form-item'>
            Partnership Deyer [az]
            <input required placeholder='Deyerini daxil edin' value={info.PartnershipDeyer || ''} type="text" onChange={(e) => handleInfoChange('PartnershipDeyer', e.target.value)} />
          </label>
        </div>
        <div className='w-60'>
          <label className='form-item'>
            Partnership baslig [ru]
            <input required placeholder='Başlıq daxil edin' value={PartnershipBasligRu || ''} type="text" onChange={(e) => setPartnershipBasligRu(e.target.value)} />
          </label>
          <label className='form-item'>
            Partnership Deyer [ru]
            <input required placeholder='Deyerini daxil edin' value={PartnershipDeyerRu || ''} type="text" onChange={(e) => setPartnershipDeyerRu(e.target.value)} />
          </label>
        </div>

        {/* Partnership Images with Remove Button */}
        <div className='w-60'>
          <div className='partnership-images'>
          <h4>Partnership Images (Max 4)</h4>
               {partnershipImages?.map((img, index) => (
            <div key={index} className='image-item'>
              <span>{img?.name || 'Uploaded image'}</span>
              <button className='btn-red' type="button" onClick={() => handleRemovePartnershipImage(index)}>Şekili silin</button>
            </div>
          ))}
          </div>
       
          {partnershipImages?.length < 4 && <input type='file' onChange={(e) => handlePartnershipImageUpload(e.target.files[0])} />}
        </div>
      </div>

      <div className='box container-4'>
        <div className='w-60'>
          <h4>Advantage Image</h4>
          
          <label className='add-img'>
            <input type='file' onChange={(e) => handleAdvantageImageUpload(e.target.files[0])} />
           <div>
           <span>{advantageImage?.name || 'Upload image'}</span>
            {advantageImage && <button className='btn-red' type="button" onClick={handleRemoveAdvantageImage}>Şekili silin</button>}
           </div>
          </label>
        </div>

        <div className='top'>
          <h4>Advantage Categories [az] (Max 4)</h4>
          <div className='advantagecontainers'>
              {info?.advantageCategory?.map((category, index) => (
            
            <div className='advantageitem' key={index}>
              <label className='form-item'>
               <img src={index ===0? packageIcon:index ===1?loaderCircleIcon:index===2?userPinIcon:targetLockIcon} alt='Advantage icon' />
                Advantage Başlıq [az]
                <input required type="text" value={category.baslig} onChange={(e) => handleAdvantageCategoryChange(index, 'baslig', e.target.value)} />
              </label>
              <label className='form-item'>
                Advantage Dəyər [az]
                <input required type="text" value={category.deyer} onChange={(e) => handleAdvantageCategoryChange(index, 'deyer', e.target.value)} />
              </label>
              <button className='btn-red' type="button" onClick={() => handleRemoveAdvantageCategory(index)}>Kateqoriyanı silin</button>
            </div>
          ))}
          </div>
        
          {info?.advantageCategory?.length < 4 && <button className='btn' type="button" onClick={handleAddAdvantageCategory}>Kateqoriya əlavə edin</button>}
        </div>
        <div className='top'>
          <h4>Advantage Categories [ru] (Max 4)</h4>
          <div className='advantagecontainers'>
              {advantageCategoryRu?.map((category, index) => (
            
            <div className='advantageitem' key={index}>
              <label className='form-item'>
               <img src={index ===0? packageIcon:index ===1?loaderCircleIcon:index===2?userPinIcon:targetLockIcon} alt='advantage icon' />
                Advantage Başlıq [ru]
                <input required type="text" value={category.baslig} onChange={(e) => handleAdvantageCategoryChangeru(index, 'baslig', e.target.value)} />
              </label>
              <label className='form-item'>
                Advantage Dəyər [ru]
                <input required type="text" value={category.deyer} onChange={(e) => handleAdvantageCategoryChangeru(index, 'deyer', e.target.value)} />
              </label>
              <button className='btn-red' type="button" onClick={() => handleRemoveAdvantageCategoryru(index)}>Kateqoriyanı silin</button>
            </div>
          ))}
          </div>
        
          {advantageCategoryRu?.length < 4 && <button className='btn' type="button" onClick={handleAddAdvantageCategoryru}>Kateqoriya əlavə edin</button>}
        </div>
      </div>

      <button type='submit' className='btn'>Yadda saxlayın</button>
    </form>
  );
}

export default EditHomePage;