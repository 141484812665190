import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';

function Pagination({ currentPage, setCurrentPage, totalPages }) {
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo({ top: 0 });
      }, [currentPage]);
    return (
        <div className='pagination'>
            <div className='item'
                onClick={() => setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage)}
                style={{ cursor: currentPage === 1 ? 'not-allowed' : 'pointer', opacity: currentPage === 1 ? 0.5 : 1 }}>{t("Geri")}</div>
            {Array.from({ length: totalPages }, (_, i) => {
                let index = i + 1
                const isActive = currentPage === index;
                return (<div style={{cursor:'pointer'}} className={`item ${isActive ? 'pagination-active' : ''}`}
                    key={index}
                    onClick={() => setCurrentPage(index)}
                    disabled={isActive}>{index}</div>)
            })}
            <div className='item'
                onClick={() => setCurrentPage(currentPage < totalPages ? currentPage + 1 : currentPage)}
                style={{ cursor: currentPage === totalPages ? 'not-allowed' : 'pointer', opacity: currentPage === totalPages ? 0.5 : 1 }}>{t("İrəli")} </div>

        </div>
    );
}

export default Pagination