

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import AddfaqItem from './AddfaqItem';
import { Base_url } from '../api_url';

function AddFaq() {
    const [products, setProducts] = useState([]);
    const [isUpdate, setIsUpdate] = useState(false); // Проверка, создаем или обновляем
    const token = Cookies.get('token_adminPage');

    useEffect(() => {
        // Получение существующих данных о FAQ
        axios.get(`${Base_url}az/faq/`)
            .then(response => {
                if (response.data) {
                    setProducts(response.data.faq.content.map((item, index) => ({
                        id: index,
                        title: item.title,
                        description: item.description,
                        ruTitle: item.translations?.ru?.title || '',
                        ruDescription: item.translations?.ru?.description || '',
                    })));
                    setIsUpdate(true); // Устанавливаем режим обновления, если данные существуют
                }
              
            })
            .catch(error => {
              
            });
    }, []);

    const handleRemoveFaq = (index) => {
        const updatedFaq = products.filter((_, i) => i !== index);
        setProducts(updatedFaq);
    };

    const addProductItem = () => {
        setProducts([...products, { id: products.length, title: '', description: '', ruTitle: '', ruDescription: '' }]);
    };

    const handleInputChange = (index, field, value) => {
        const updatedProducts = [...products];
        updatedProducts[index][field] = value;
        setProducts(updatedProducts);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Подготовка данных в формате content[]
        const formData = new FormData();
        products.forEach((product, index) => {
            formData.append(`content[${index}][title]`, product.title);
            formData.append(`content[${index}][description]`, product.description);
            formData.append(`content[${index}][translations][ru][title]`, product.ruTitle); // Русский заголовок
            formData.append(`content[${index}][translations][ru][description]`, product.ruDescription); // Русское описание
        });

        try {
            if (isUpdate) {
                // Обновляем FAQ
                await axios.post(`${Base_url}admin/faq/update`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
                alert("Tez-tez verilən suallar uğurla yeniləndi");
            } else {
                // Создаем новый FAQ
                await axios.post(`${Base_url}admin/faq/create`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
                alert("Tez-tez verilən suallar uğurla yaradıldı");
            }
        } catch (error) {
          
        }
    };

    return (
        <form className='right-content add-faq' onSubmit={handleSubmit}>
            <div className='box container-3'>
                {products.map((product, index) => (
                    <>
                        <AddfaqItem
                            key={index}
                            product={product}
                            onChange={(field, value) => handleInputChange(index, field, value)}
                        />
                        <button className='btn-red' type="button" onClick={() => handleRemoveFaq(index)}>Kateqoriyanı silin</button>
                    </>
                ))}
                <div className='btn' onClick={addProductItem}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                    </svg>Setir əlavə edin</div>
            </div>
            <button className='btn' type='submit'>
                Yadda saxlayın
            </button>
        </form>
    );
}

export default AddFaq;