import React from 'react'
import Cookies from 'js-cookie';
function Dashboard({ pageContent, setPageContent,dropdowIsVisible,setDropdowIsVisible }) {
    const handleExit = () =>{
        Cookies.remove('token_adminPage');
        Cookies.remove('visible_id');
        Cookies.remove('Admin_id');
        window.location.reload()
    }
    return (
        <ul className='dashboard'>
            <li className='dashboard-dropdown'>
                <p onClick={() => {
                    setDropdowIsVisible(1);
                    setPageContent(1)
                }} className={`dashboard-item ${dropdowIsVisible === 1 ? 'active' : ''}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house" viewBox="0 0 16 16">
                        <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5z" />
                    </svg>
                    Dashboard
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron bi-chevron-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                    </svg>
                </p>
                <ul className={`dropdown-bottom ${dropdowIsVisible === 1 ? 'active' : ''}`}>
                    <li onClick={() => setPageContent(1)} className={`dropdown-link ${pageContent === 1 ? 'active' : ''}`}>Ana səhifə</li>
                    <li onClick={() => setPageContent(2)} className={`dropdown-link ${pageContent === 2 ? 'active' : ''}`}>Footer</li>
                </ul>
            </li>
            <li className='dashboard-dropdown'>
                <p onClick={() => {
                    setDropdowIsVisible(2);
                    setPageContent(3)
                }} className={`dashboard-item ${dropdowIsVisible === 2 ? 'active' : ''}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-shop" viewBox="0 0 16 16">
                        <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.37 2.37 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0M1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5M4 15h3v-5H4zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1zm3 0h-2v3h2z" />
                    </svg>
                    Məhsullar
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron bi-chevron-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                    </svg>
                </p>
                <ul className={`dropdown-bottom ${dropdowIsVisible === 2 ? 'active' : ''}`}>
                    <li onClick={() => setPageContent(3)} className={`dropdown-link ${pageContent === 3 ? 'active' : ''}`}>Məhsul yarat</li>
                    <li onClick={() => setPageContent(4)} className={`dropdown-link ${pageContent === 4 ? 'active' : ''}`}>Məhsulları izle</li>
                </ul>
            </li>
            <li className='dashboard-dropdown'>
                <p onClick={() => {
                    setDropdowIsVisible(3);
                    setPageContent(5)
                }} className={`dashboard-item ${dropdowIsVisible === 3 ? 'active' : ''}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-activity" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2" />
                    </svg>
                    Fəaliyyət
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron bi-chevron-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                    </svg>
                </p>
                <ul className={`dropdown-bottom ${dropdowIsVisible === 3 ? 'active' : ''}`}>
                    <li onClick={() => setPageContent(5)} className={`dropdown-link ${pageContent === 5 ? 'active' : ''}`}>Fəaliyyət yarat</li>
                    <li onClick={() => setPageContent(6)} className={`dropdown-link ${pageContent === 6 ? 'active' : ''}`}>Fəaliyyəti izle</li>
                </ul>
            </li>
            <li className={`dashboard-item ${pageContent === 7 ? 'active' : ''}`} onClick={() => {
                setPageContent(7);
                setDropdowIsVisible(0)
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                </svg>
                User
            </li>
            <li className={`dashboard-item ${pageContent === 8 ? 'active' : ''}`} onClick={() => {
                setPageContent(8);
                setDropdowIsVisible(0)
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                </svg>
                Haqqımızda
            </li>
            <li onClick={() => {
                setPageContent(9);
                setDropdowIsVisible(0)
            }} className={`dashboard-item ${pageContent === 9 ? 'active' : ''}`}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94" />
                </svg>
                FAQ
            </li>
            <li className='exitAdmin' onClick={()=>handleExit()}>
            <i className="fa-solid fa-right-from-bracket"></i>
            Çıxış
            </li>
        </ul>
    )
}

export default Dashboard