

import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ServiceItem from '../components/ServiceItem';
import { SwiperSlide, Swiper } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import { useTranslation } from 'react-i18next';
import Loading from '../components/Loading';
import { Base_url, img_url } from '../api_url';

function ServiceInfo() {
  const { i18n,t } = useTranslation();
  const { slug } = useParams();
  const [serviceDetail, setServiceDetail] = useState(null);
  const [relatedServices, setRelatedServices] = useState([]);
  const [bigImg, setBigImg] = useState('');
  const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);
  const fetchServiceDetail = useCallback(async () => {
    try {
      const response = await fetch(`${Base_url}${i18n.language}/services/${slug}`);
      const data = await response.json();
      if (data.status) {
        setServiceDetail(data.service);
        const coverImage = data.service.images?.find(image => image.is_cover);
        setLoading(false);
        if (coverImage) {
          setBigImg(coverImage.path);
        }
      }
    } catch (error) {
      console.error('Ошибка получения данных услуги:', error);
      setLoading(false);
    }
  }, [i18n.language, slug]); // Добавляем зависимости
  
  const fetchRelatedServices = useCallback(async () => {
    try {
      const response = await fetch(`${Base_url}${i18n.language}/services`);
      const data = await response.json();
      if (data.status) {
        setRelatedServices(data.services.filter(item=>item.slug !== slug)?.slice(-6));
      }
    } catch (error) {
      console.error('Ошибка получения списка услуг:', error);
    }
  }, [i18n.language,slug]); // Добавляем зависимости
  
  useEffect(() => {
    fetchServiceDetail();
    fetchRelatedServices();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [fetchServiceDetail, fetchRelatedServices]); // Добавляем мемоизированные функции в зависимости
  if (loading) {
    return <Loading></Loading>;
  }
  // console.log(serviceDetail.id);
  return (
    <>
      <section className='container service-info'>
        <div className='right box rightInfo'>
          {serviceDetail ? (
            <>
              <div className='review'>
                <div className='small-images'>
                  {serviceDetail.images?.map((image, index) => (
                    <div className='item' key={index} onClick={() => setBigImg(image.path)}>
                      <img height='100%' width='100%' src={`${img_url}${image.path}`} alt={image.alt || serviceDetail.title} />
                    </div>
                  ))}
                </div>
                <div className='big-image'>
                  <img height='100%' width='100%' src={`${img_url}${bigImg}`} alt={serviceDetail.title} />
                </div>
                <div className='text'>
                  <span>{serviceDetail.title}</span>
                  <p>{serviceDetail.description}</p>
                </div>
              </div>
              <ul className='characteristics'>
                {serviceDetail.properties &&
                  (typeof serviceDetail.properties === 'string'
                    ? JSON.parse(serviceDetail.properties)
                    : serviceDetail.properties
                  ).map((property, index) => (
                    <li key={index}>
                      {
                        i18n.language === 'az'?
                        (<> <span>{property.logo}</span><span>{property.text}</span></>)
                        :
                        (<><span>{property.translations.ru.logo}</span><span>{property.translations.ru.text}</span></>)
                      }
                     
                    </li>
                  ))}
              </ul>
            </>
          ) : (
            <p>Загрузка данных...</p>
          )}
        </div>
      </section>

      <section className='related-topics container'>
        <h3>{t("Əlaqədarmövzular")}</h3>
        <Swiper
          spaceBetween={16}
          slidesPerView={3}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
          breakpoints={{
            320: {
              slidesPerView: 2,
              spaceBetween: 16,
            },
            768: {
              slidesPerView: 4.4,
              spaceBetween: 16,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 16,
            },
          }}
        >
          {relatedServices?.length > 0 ? (
            relatedServices.map((service) => (
              <SwiperSlide key={service.id}>
                <ServiceItem item={service} />
              </SwiperSlide>
            ))
          ) : (
            <p>Загрузка...</p>
          )}
        </Swiper>
      </section>
    </>
  );
}

export default ServiceInfo;
