

import React, { useState } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { Base_url } from '../api_url';

function AddActivity() {
  const [name, setName] = useState('');
  const [runame, setruName] = useState('');

  const [image, setImage] = useState(null); // Хранение изображения для отправки
  const [imageUrl, setImageUrl] = useState(''); // Состояние для хранения URL загруженного файла

  // Функция для обработки файла изображения
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file); // Сохраняем изображение в стейте для отправки
      const fileUrl = URL.createObjectURL(file); // Создаем URL для отображения изображения
      setImageUrl(fileUrl); // Сохраняем URL в состоянии
    }
  };

  // Обработка отправки формы
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Получение токена из cookies
    const token = Cookies.get('token_adminPage');

    // Создание объекта FormData для отправки данных с изображением
    const formData = new FormData();
    formData.append('name', name);
    formData.append('translations[ru][name]', runame);
    formData.append('image', image); // Отправляем файл изображения

    try {
      // Отправка запроса через axios
      const response = await axios.post(
        `${Base_url}admin/activities/create`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`, // Добавляем токен авторизации в заголовки
            'Content-Type': 'multipart/form-data', // Важный заголовок для отправки файлов
          },
        }
      );

      // Проверяем, успешно ли отправлены данные
      if (response.data.status === true) {
        alert('Ugurla Yaradildi');
        // Очистить поля формы после успешной отправки
        setName('');
        setruName('');
        setImage(null);
        setImageUrl('');
      } else {
        alert('Ошибка при отправке данных');
      }
    } catch (error) {
      // console.error('Ошибка при отправке:', error);
      alert('Произошла ошибка при отправке данных.');
    }
  };

  return (
    <form className='right-content add-activity' onSubmit={handleSubmit}>
      <div className='box container-3'>
        <div className='w-40'>
          <div className='form-box'>
            <label className='form-item'>
              Başlıq[az]
              <input
                placeholder='Başlıq daxil edin'
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </label>
          </div>
          <div className='form-box'>
            <label className='form-item'>
              Başlıq[ru]
              <input
                placeholder='Başlıq daxil edin'
                // value={name}
                onChange={(e) => setruName(e.target.value)}
                required
              />
            </label>
          </div>
        </div>
        <div className='w-60'>
          <h4>Qapaq şəkli</h4>
          <div className="add-img-wrapper">
            <label className='add-img'>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-arrow-up" viewBox="0 0 16 16">
                <path d="M8.5 11.5a.5.5 0 0 1-1 0V7.707L6.354 8.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 7.707z" />
                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
              </svg>
              Upload image
              <input type="file" accept="image/*" onChange={handleFileChange} hidden />
            </label>

            {/* Отображаем ссылку на изображение, если оно загружено */}
            {imageUrl && (
              <div className="image-preview">
                <img src={imageUrl} alt={'Yeni Fəaliyət'}/>
              </div>
            )}
          </div>
        </div>
      </div>
      <button className='btn' type='submit'>Yadda saxlayın</button>
    </form>
  );
}

export default AddActivity;
