

import React, { useState } from 'react';
import { useAuth } from '../AuthContext'; // Предполагается, что у вас есть контекст авторизации
import Cookies from 'js-cookie'; // Для работы с cookies
import { useNavigate } from 'react-router-dom'; // Импортируем useNavigate для перенаправления
import { Base_url } from '../api_url';

const Login = () => {
  const { login } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null); // Для отображения ошибок
  const navigate = useNavigate(); // Инициализация useNavigate

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Отправка POST-запроса
      const response = await fetch(`${Base_url}admin/login/submit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      // Обработка ответа от сервера
      const data = await response.json();

      if (response.ok) {
        // Сохраняем токен в cookies на 30 день
        Cookies.set('token_adminPage', data.token, { expires: 30 });

        // Вызываем функцию логина из контекста
        login(data.token);

        // Перенаправляем пользователя на страницу admin/home
        navigate('/admin/home');
      } else {
        // Обрабатываем ошибки (например, неверный email или пароль)
        setError(data.message || 'Login failed');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <div className='login'>
      <form onSubmit={handleSubmit} className='box'>
        <label>
          E-poçt
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder='Elektron poçt ünvanınızı daxil edin'
          />
        </label>
        <label>
          Şifrə
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder='Şifrənizi daxil edin'
          />
        </label>
        {error && <p className='error'>E-poçt və ya Şifrə səhvdir</p>}
        <label className='login-checkbox'>
          <input type='checkbox' />
          <div className="checkmark">
            <span>✓</span>
          </div>
          Məni xatırla
        </label>
        <button className='btn' type="submit">Daxil ol</button>
      </form>
    </div>
  );
};

export default Login;
