


import React, { useEffect, useState } from 'react';
import FollowActivityItem from './FollowActivityItem';
import Cookies from 'js-cookie'; // Для авторизации
import EditActivity from './EditActivity';
import { Base_url } from '../api_url';

function FollowActivity() {
  const [activities, setActivities] = useState([]);
  const [activitiesRu, setActivitiesRu] = useState([]);
  const [error, setError] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState(null); // Для хранения активности для обновления
  const [selectedActivityru, setSelectedActivityru] = useState(null); // Для хранения активности для обновления
  const [isUpdatePopupOpen, setIsUpdatePopupOpen] = useState(false); // Контроль открытия попапа
  const [Refreshget, setrefreshget] = useState(false); // Контроль открытия попапа

  const token = Cookies.get('token_adminPage');

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await fetch(`${Base_url}az/activities`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`, // Добавляем токен авторизации в заголовки
          },
        });
        const data = await response.json();
        if (data.status) {
          setActivities(data.activities);
          setrefreshget(false)
        } else {
          setError('Не удалось загрузить данные.');
        }
      } catch (error) {
        setError('Ошибка при загрузке данных.');
      }
    };

    fetchActivities();
  }, [token,Refreshget]);
  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await fetch(`${Base_url}ru/activities`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`, // Добавляем токен авторизации в заголовки
          },
        });
        const data = await response.json();
        if (data.status) {
          setActivitiesRu(data.activities);
          setrefreshget(false)
        } else {
          setError('Не удалось загрузить данные.');
        }
      } catch (error) {
        setError('Ошибка при загрузке данных.');
      }
    };

    fetchActivities();
  }, [token,Refreshget]);

  // Функция для удаления активности
  const handleDelete = async (id) => {
    if (window.confirm('Bu fəaliyyəti silmək istədiyinizə əminsiniz?')) {
      try {
        const response = await fetch(`${Base_url}admin/activities/${id}/delete`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (data.status) {
          setActivities(activities.filter(activity => activity.id !== id));
          alert('Fəaliyyət uğurla silindi.');
        } else {
          alert('Fəaliyyəti silərkən xəta baş verdi.');
        }
      } catch (error) {
        alert('Silinmə zamanı xəta baş verdi.');
      }
    }
  };

  // Функция для открытия попапа обновления активности
  const handleUpdate = (activity) => {
    const data = activitiesRu.find(item => item.id === activity.id)
    setSelectedActivityru(data)
    setSelectedActivity(activity);
    setIsUpdatePopupOpen(true); // Открываем попап
  };

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className='right-content follow-list follow-activity'>
      <div className='box'>
        <div className='bottom'>
          {activities.map((activity, index) => (
            <FollowActivityItem
              key={activity.id}
              index={index + 1}
              name={activity.name}
              date={new Date(activity.created_at).toLocaleDateString()} // Форматируем дату
              image={`${activity.image.path}`} // Путь к изображению
              onDelete={() => handleDelete(activity.id)} // Обработка удаления
              onUpdate={() => handleUpdate(activity)} // Обработка обновления
            />
          ))}
        </div>
      </div>

      {/* Попап для обновления активности */}
      {isUpdatePopupOpen && (
        <div className="popup">
        
          <EditActivity
            activity={selectedActivity} // Передаем выбранную активность для редактирования
            activityru={selectedActivityru} // Передаем выбранную активность для редактирования
            onClose={() => setIsUpdatePopupOpen(false)} // Закрываем попап после обновления
            setrefreshget={setrefreshget}
          />
        </div>
      )}
    </div>
  );
}

export default FollowActivity;
