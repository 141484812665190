

import React, { useCallback, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css'; // Импорт стилей Swiper
import ActivityItem from '../components/ActivityItem';
import { useTranslation } from 'react-i18next';
import { Base_url } from '../api_url';

function Fealiyyetler() {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { i18n,t } = useTranslation();
  // Функция для выполнения GET-запроса к API
  const fetchActivities = useCallback(async () => {
    try {
      const response = await fetch(`${Base_url}${i18n.language}/activities`);
      if (!response.ok) {
        throw new Error('Ошибка при загрузке данных');
      }
      const data = await response.json();
      setActivities(data.activities); // Сохраняем полученные данные в состоянии
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }, [i18n.language]); // Добавляем i18n.language в зависимости
  
  // Вызов fetchActivities при загрузке компонента
  useEffect(() => {
    fetchActivities();
  }, [fetchActivities]); // Теперь добавляем fetchActivities в зависимости

  // Обработка различных состояний загрузки и ошибок
  if (loading) {
    return <p>Загрузка данных, пожалуйста, подождите...</p>;
  }

  if (error) {
    return <p>Ошибка: {error}</p>;
  }

  return (
    <section className='container fealiyyetler'>
      <h3>{t("ensonfealiyetler")}</h3>
      <Swiper
        spaceBetween={16}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        className="mySwiper"
        breakpoints={{
          576: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2.4,
          },
          992: {
            slidesPerView: 3,
          },
        }}
      >
        {/* Отображение данных активности в слайдерах */}
        {activities?.map((activity,index) => (
          <SwiperSlide key={index}>
            <ActivityItem
              name={activity.name}
              date={activity.created_at.slice(0,10)}
              time={activity.created_at.slice(11,16)}
              image={activity.image?.path} // Проверка на наличие изображения
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
}

export default Fealiyyetler;
