

import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import FollowProductItem from './FollowProductItem';
import Cookies from 'js-cookie';
import { Base_url } from '../api_url';
function FollowProduct() {
    const [products, setProducts] = useState([]);
    const token = Cookies.get('token_adminPage');
    // GET запрос для получения списка продуктов
    const fetchProducts = useCallback(async () => {
        try {
          const response = await axios.get(`${Base_url}az/services`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.data.status) {
            setProducts(response.data.services); // Предполагается, что API возвращает список продуктов в поле services
          }
        } catch (error) {
          console.error('Ошибка при получении продуктов:', error);
        }
      }, [token]); // Добавляем token в зависимости, так как он используется внутри функции
      
      // Вызов fetchProducts при монтировании компонента
      useEffect(() => {
        fetchProducts();
      }, [fetchProducts]); // Добавляем fetchProducts в зависимости
    // DELETE запрос для удаления продукта
    const handleDelete = async (slug) => {
        try {
            await axios.delete(`${Base_url}admin/services/${slug}/delete`,{
                headers: {
                    Authorization: `Bearer ${token}`
                  }
            });
            // После удаления обновляем список продуктов
            setProducts(products.filter(product => product.slug !== slug));
        } catch (error) {
            console.error('Ошибка при удалении продукта:', error);
        }
    };
    return (
        <div className='right-content follow-list follow-products'>
            <div className='box'>

                <div className='bottom'>
                    {/* Отображаем список продуктов */}
                    {products.map((product,index) => (
                        <FollowProductItem
                            key={index}
                            product={product}
                            onDelete={handleDelete} // Передаем функцию удаления как пропс
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default FollowProduct;
