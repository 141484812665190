
import React, { useEffect, useState } from 'react';
import logo from '../image/Untitled-1-02.svg';
import logoMobile from '../image/Untitled-1-01.svg';
import mobileCatIcon from '../image/icons/mobile-cat.png';
import mobileMenuIcon from '../image/icons/mobile-menu.png';
import xIcon from '../image/icons/x-icon.png';
import { Link, NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Languages from './Languages';

function Nav({ showMenu, setShowMenu, showMediaFilter, setShowMediaFilter }) {

  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (inputValue) {
      setSearchParams({ search: inputValue });
    } else {
      searchParams.delete('search');
      setSearchParams(searchParams); // обновляем параметры после удаления
    }
  }, [inputValue, setSearchParams, searchParams]);

  const handleFocus = () => {
    navigate('services');
    if (inputValue === '') {
      setIsFocused(true);
    }
  };

  const handleBlur = () => {
    if (inputValue === '') {
      setIsFocused(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const verticalScroll = window.scrollY;
      const sticky = document.querySelector(".Header");

      if (verticalScroll > 100) {
        sticky.classList.add("is-sticky");
      } else {
        sticky.classList.remove("is-sticky");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className='Header'>
      <div className="container">
        <Link
          to="/services"
          className="mobile-filter-btn"
          onClick={() => {
            setShowMediaFilter(!showMediaFilter);
            setShowMenu(false);
          }}
        >
          <img src={showMediaFilter ? xIcon : mobileCatIcon} alt="Filter Icon" />
        </Link>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        <div className="logoMobile">
          <Link to="/">
            <img src={logoMobile} alt="Logo" />
          </Link>
        </div>
        <div
          className="mobile-menu-btn"
          onClick={() => {
            setShowMenu(!showMenu);
            setShowMediaFilter(false);
          }}
        >
          <img src={showMenu ? xIcon : mobileMenuIcon} alt="Menu Icon" />
        </div>
        <ul className="nav-menu">
          <li>
            <NavLink to="/" className="nav-link">
              {t('anaseyfe')}
            </NavLink>
          </li>
          <li>
            <NavLink to="/services" className="nav-link">
              {t("XIDMƏTLƏR")}
            </NavLink>
          </li>
          <li>
            <NavLink to="/activity" className="nav-link">
            {t("FƏALIYYƏT")}
            </NavLink>
          </li>
          <li>
            <NavLink to="/about" className="nav-link">
            {t("HAQQIMIZDA")}
            </NavLink>
          </li>
        </ul>
        <div className="nav-right">
          <label htmlFor="search" className={`${isFocused ? 'focused' : ''}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
            </svg>
            <input
              placeholder={t("axtarış")}
              type="text"
              id="search"
              value={inputValue}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={(e) => setInputValue(e.target.value)}
              style={{ opacity: isFocused || inputValue !== '' ? '1' : '0' }}
            />
            {(!isFocused && inputValue === '') && <span style={{ display: 'inline' }}>{t('axtarış')}...</span>}
          </label>
          <Languages />
        </div>
      </div>
    </nav>
  );
}

export default Nav;
