

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Base_url, img_url } from '../api_url';

function EditAbout() {
    const [title, setTitle] = useState(''); // Азербайджанский язык
    const [subtitle, setSubtitle] = useState('');
    const [ruTitle, setRuTitle] = useState(''); // Русский язык
    const [ruSubtitle, setRuSubtitle] = useState('');
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(''); // Для отображения URL изображения
    const [isUpdate, setIsUpdate] = useState(false);
    const token = Cookies.get('token_adminPage');

    useEffect(() => {
        // Получаем данные "О нас"
        axios.get(`${Base_url}az/about-us`)
            .then((response) => {
                if (response.data && response.data.about_us) {
                    setTitle(response.data.about_us.title || '');
                    setSubtitle(response.data.about_us.subtitle || '');
                    setImage(response.data.about_us.image || null); // Сохраняем старое изображение
                    setImageUrl(`${img_url}${response.data.about_us.image?.path || ''}`); // Устанавливаем URL старого изображения
                    setIsUpdate(true);
                }
            })
            .catch((error) => {
            });
    }, [token]);
    useEffect(() => {
        // Получаем данные "О нас"
        axios.get(`${Base_url}ru/about-us`)
            .then((response) => {
                if (response.data && response.data.about_us) {
                    setRuTitle(response.data.about_us.title || '');
                    setRuSubtitle(response.data.about_us.subtitle || '');
                    setImage(response.data.about_us.image || null); // Сохраняем старое изображение
                    setImageUrl(`${img_url}${response.data.about_us.image?.path || ''}`); // Устанавливаем URL старого изображения
                    // setIsUpdate(true);
                }
            })
            .catch((error) => {
                console.error('Ошибка при получении данных о нас', error);
            });
    }, [token]);

    // Обработка загрузки нового изображения
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImage(file); // Сохраняем новое изображение в стейте
            const fileUrl = URL.createObjectURL(file); // Создаем URL для отображения нового изображения
            setImageUrl(fileUrl); // Сохраняем URL в состоянии
        }
    };

    // Обработка отправки формы
    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('title', title); // Азербайджанский текст
        formData.append('subtitle', subtitle);

        // Добавляем русский текст в translations
        formData.append('translations[ru][title]', ruTitle); // Русский текст
        formData.append('translations[ru][subtitle]', ruSubtitle);

        // Проверяем, если новое изображение загружено
        if (image && image instanceof File) {
            formData.append('image', image); // Добавляем новое изображение
        } else if (image && typeof image === 'string') {
            // Если новое изображение не выбрано, отправляем старое изображение
            formData.append('old_image', image); // Отправляем старое изображение
        }
        try {
            if (isUpdate) {
                // Обновление данных
                
                await axios.post(`${Base_url}admin/about-us/update`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
                alert('Məlumat uğurla yeniləndi');
            } else {
                
                
                await axios.post(`${Base_url}admin/about-us/create`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
                alert("Məlumat uğurla yaradıldı");
            }
        } catch (error) {
            console.error('Ошибка при сохранении информации', error);
        }
    };

    return (
        <form className='right-content edit-about' onSubmit={handleSubmit}>
            <div className='box container-3'>
                <div className='w-40'>
                    {/* Азербайджанский текст */}
                    <div className='form-box'>
                        <h3>Azərbaycan dili</h3>
                        <label className='form-item'>
                            Başlıq (AZ)
                            <input
                                placeholder='Başlıq daxil edin'
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                required
                            />
                        </label>
                        <label className='form-item'>
                            Dəyər (AZ)
                            <textarea
                                rows={8}
                                placeholder='Dəyər daxil edin'
                                value={subtitle}
                                onChange={(e) => setSubtitle(e.target.value)}
                            ></textarea>
                        </label>
                    </div>

                    {/* Русский текст */}
                    <div className='form-box'>
                        <h3>Русский язык</h3>
                        <label className='form-item'>
                            Заголовок (RU)
                            <input
                                placeholder='Введите заголовок'
                                value={ruTitle}
                                onChange={(e) => setRuTitle(e.target.value)}
                                required
                            />
                        </label>
                        <label className='form-item'>
                            Описание (RU)
                            <textarea
                                rows={8}
                                placeholder='Введите описание'
                                value={ruSubtitle}
                                onChange={(e) => setRuSubtitle(e.target.value)}
                            ></textarea>
                        </label>
                    </div>
                </div>

                <div className='w-60'>
                    <h4>Qapaq şəkli</h4>
                    <label className='add-img'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-arrow-up" viewBox="0 0 16 16">
                            <path d="M8.5 11.5a.5.5 0 0 1-1 0V7.707L6.354 8.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 7.707z" />
                            <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                        </svg>
                        Upload image
                        <input type="file" accept="image/*" onChange={handleFileChange} hidden />
                    </label>
                    {imageUrl && (
                        <div className='image-preview'>
                            <img src={imageUrl} alt="Selected cover" />
                        </div>
                    )}
                </div>
            </div>
            <button className='btn' type='submit'>
                Fəaliyyəti paylaşın
            </button>
        </form>
    );
}

export default EditAbout;