
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Base_url, img_url } from '../api_url';

// AddImg component for image upload
function AddImg({ onUpload, index }) {
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      onUpload(file, index); // Pass file and index to the parent component
    }
  };

  return (
    <label className='add-img'>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-arrow-up" viewBox="0 0 16 16">
        <path d="M8.5 11.5a.5.5 0 0 1-1 0V7.707L6.354 8.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 7.707z" />
        <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
      </svg>
      Upload image
      <input type='file' accept='image/*' hidden onChange={handleFileChange} />
    </label>
  );
}

function EditProductModal({ product, onClose }) {
  const [category, setCategory] = useState(product.category_id || 0);
  const [categories, setCategories] = useState([]);
  const [titleAz, setTitleAz] = useState(product.title || '');
  const [descriptionAz, setDescriptionAz] = useState(product.description || '');
  const [titleRu, setTitleRu] = useState('');
  const [descriptionRu, setDescriptionRu] = useState('');
  const [properties, setProperties] = useState([]);
  const [images, setImages] = useState(product.images || []);
  const [coverImageId, setCoverImageId] = useState(product.images?.find(img => img.is_cover)?.id || null);
  const [imagePreviews, setImagePreviews] = useState(product.images.map(img => `${img_url}${img.path}`));

  const token = Cookies.get('token_adminPage');

  useEffect(() => {
    // Fetch categories
    axios.get(`${Base_url}az/categories/`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(response => setCategories(response.data.categories || []))
    .catch(error => console.error('Ошибка при получении категорий', error));

    // Fetch Russian data
    axios.get(`${Base_url}ru/services/${product.slug}/`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(response => {
      const service = response.data.service;
      setTitleRu(service.title);
      setDescriptionRu(service.description);
      setProperties(service.properties.map(prop => ({
        logo: prop.logo,
        text: prop.text,
        logoRu: prop.translations?.ru?.logo || '',
        textRu: prop.translations?.ru?.text || ''
      })));
    })
    .catch(error => console.error('Ошибка при получении данных на русском', error));
  }, [product.slug, token]);

  const addPropertyItem = () => {
    setProperties([...properties, { logo: '', text: '', logoRu: '', textRu: '' }]);
  };

  const handleRemoveProperty = (index) => {
    const updatedProperties = properties.filter((_, i) => i !== index);
    setProperties(updatedProperties);
  };

  const handlePropertyChange = (index, key, value) => {
    const updatedProperties = [...properties];
    updatedProperties[index][key] = value;
    setProperties(updatedProperties);
  };

  const handleImageUpload = (file, index) => {
    const updatedImages = [...images];
    updatedImages[index] = file;
    setImages(updatedImages);

    const updatedPreviews = [...imagePreviews];
    updatedPreviews[index] = URL.createObjectURL(file);
    setImagePreviews(updatedPreviews);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!titleAz || !descriptionAz || !titleRu || !descriptionRu) {
      return alert('Пожалуйста, заполните все поля для азербайджанского и русского языков.');
    }

    const formData = new FormData();
    formData.append('title', titleAz);
    formData.append('description', descriptionAz);
    formData.append('translations[ru][title]', titleRu);
    formData.append('translations[ru][description]', descriptionRu);
    formData.append('category_id', category);
    formData.append('cover_image_id', coverImageId);

    properties.forEach((property, index) => {
      formData.append(`properties[${index}][logo]`, property.logo);
      formData.append(`properties[${index}][text]`, property.text);
      formData.append(`properties[${index}][translations][ru][logo]`, property.logoRu);
      formData.append(`properties[${index}][translations][ru][text]`, property.textRu);
    });

    images.forEach((image, index) => {
      if (image instanceof File) {
        formData.append(`images[${index}]`, image);
      } else {
        formData.append(`existing_images[${index}]`, image.id); // Retain existing image ID
      }
    });

    try {
      await axios.post(`${Base_url}admin/services/${product.slug}/update`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      window.location.reload();
      onClose();
    } catch (error) {
      console.error('Ошибка при обновлении сервиса', error);
    }
  };

  return (
    <div className='EditProducts'>
      <form className='right-content add-product' onSubmit={handleSubmit}>
        <div className='box container-1'>
          <div className='top'>
            <div className='w-40'>
              <h4>Kateqoriya deyişdir</h4>
              <select className='editProductselect' value={category} onChange={(e) => setCategory(e.target.value)}>
                {categories.map((cat) => (
                  <option key={cat.id} value={cat.id}>{cat.name}</option>
                ))}
              </select>
            </div>
            <div className='w-40'>
              <label className='form-item'>
                Məhsulun adı[az]
                <input required value={titleAz} onChange={(e) => setTitleAz(e.target.value)} />
              </label>
              <label className='form-item'>
                Məhsulun adı[ru]
                <input required value={titleRu} onChange={(e) => setTitleRu(e.target.value)} />
              </label>
              <label className='form-item'>
                Dəyər[az]
                <textarea required value={descriptionAz} onChange={(e) => setDescriptionAz(e.target.value)} />
              </label>
              <label className='form-item'>
                Dəyər[ru]
                <textarea required value={descriptionRu} onChange={(e) => setDescriptionRu(e.target.value)} />
              </label>
            </div>
          </div>
          <div className='w-60'>
            <h4>Məhsulun şəkilləri</h4>
            <div className='add-img-box'>
              {images.map((img, index) => (
                <div key={index} className='image-item'>
                  <input
                    type='radio'
                    name='cover-image'
                    checked={coverImageId === (img.id || index)}
                    onChange={() => setCoverImageId(img.id || index)}
                  />
                  <AddImg onUpload={handleImageUpload} index={index} />
                  {imagePreviews[index] && (
                    <img className='image-preview' src={imagePreviews[index]} alt='Превью изображения' />
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className='form-bottom'>
            {properties.map((property, index) => (
              <div key={index} className='form-box'>
                <div className='formAz'>
                  <label className='form-item'>
                    Başlıq[az]
                    <input
                      type='text'
                      value={property.logo}
                      onChange={(e) => handlePropertyChange(index, 'logo', e.target.value)}
                    />
                  </label>
                  <label className='form-item'>
                    Dəyər[az]
                    <input
                      type='text'
                      value={property.text}
                      onChange={(e) => handlePropertyChange(index, 'text', e.target.value)}
                    />
                  </label>
                </div>
                <div className='formAz'>
                  <label className='form-item'>
                    Başlıq[ru]
                    <input
                      type='text'
                      value={property.logoRu}
                      onChange={(e) => handlePropertyChange(index, 'logoRu', e.target.value)}
                    />
                  </label>
                  <label className='form-item'>
                    Dəyər[ru]
                    <input
                      type='text'
                      value={property.textRu}
                      onChange={(e) => handlePropertyChange(index, 'textRu', e.target.value)}
                    />
                  </label>
                </div>
                <button className='btn-red' type='button' onClick={() => handleRemoveProperty(index)}>Kateqoriyanı silin</button>
              </div>
            ))}
            <button className='btn' type='button' onClick={addPropertyItem}>Setir əlavə edin</button>
          </div>
        </div>
        <div className='forms_button'>
          <button type='submit' className='btn'>Yadda saxlayın</button>
          <button onClick={onClose} type='button' className='btnBagla'>Bagla</button>
        </div>
      </form>
    </div>
  );
}

export default EditProductModal;
