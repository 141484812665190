import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { Base_url } from '../api_url';

function User() {
  const [email, setEmail] = useState('');          // Состояние для email
  const [password, setPassword] = useState('');    // Состояние для пароля
  const [showPassword, setShowPassword] = useState(false); // Состояние для отображения пароля
  const navigate = useNavigate()
  const handleSubmit = async (e) => {
    e.preventDefault(); // Предотвращаем перезагрузку страницы

    try {
      const token = Cookies.get('token_adminPage'); // Получаем токен из Cookies

      // Отправка POST-запроса на обновление профиля
      const response = await axios.post(`${Base_url}admin/profile/update`, {
        email: email,     // Передаем email
        password: password // Передаем пароль
      }, {
        headers: {
          Authorization: `Bearer ${token}`, // Передаем токен в заголовках
        },
      });

      if (response.status === 200) {
        // Успешное обновление, удаляем токен
        Cookies.remove('token_adminPage');
        Cookies.remove('visible_id');
        Cookies.remove('Admin_id');
        alert('Профиль успешно обновлен');
        navigate('/admin/login')
      }
    } catch (error) {
      console.error('Ошибка при обновлении профиля:', error);
      alert('Произошла ошибка при обновлении профиля.');
    }
  };

  return (
    <form className='edit-user right-content' onSubmit={handleSubmit}>
      <div className='box container-1'>
        <div className='form-box'>
          <label className='form-item'>
            E-poçt
            <input 
              placeholder='E-poçt daxil edin' 
              type="email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required 
            />
          </label>
          <label className='form-item'>
            Şifrə
            <input 
              placeholder='Şifrə daxil edin' 
              type={showPassword ? 'text' : 'password'} 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              required 
            />
          </label>
        </div>
        <label className='login-checkbox'>
          <input 
            type='checkbox' 
            checked={showPassword} 
            onChange={() => setShowPassword(!showPassword)} 
          />
          <div className='checkmark'>
            <span>✓</span>
          </div>
          Şifrəni göstər
        </label>
      </div>
      <button type='submit' className='btn'>Yadda saxlayın</button>
    </form>
  );
}

export default User;
