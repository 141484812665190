

import React from 'react';
import { useNavigate } from 'react-router-dom'; // Хук для навигации

function FilterItem({ setShowMediaFilter,item, setSearchParams, categoryId }) {
    const navigate = useNavigate(); // Используем для навигации между страницами

    // Функция для изменения категории в URL при клике
    const handleCategoryClick = () => {
        // Переходим на страницу /services
        navigate('/services');
        
        // Устанавливаем категорию как параметр запроса
        setSearchParams({ category: item.slug });
    };

    return (
        <div className={`filter-top ${categoryId === item.slug ? "categoryActive" : ''}`} onClick={handleCategoryClick}>
            <span onClick={()=>setShowMediaFilter?setShowMediaFilter(false):''}>{item.name}</span>
        </div>
    );
}

export default FilterItem;


