import React from 'react'
import Commet from 'react-loading-indicators/Commet'

function Loading() {
  return (
    <>
    <div className="loadin_container">

     <Commet color="#32cd32" size="medium" text="" textColor="" />
    </div>
    </>
  )
}

export default Loading