import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Base_url } from "../api_url";
import Modal from "./Modal";

// AddImg component for image upload
function AddImg({ onUpload, index }) {
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      onUpload(file, index); // Передача файла и индекса в родительский компонент
    }
  };

  return (
    <label className="add-img">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-file-earmark-arrow-up"
        viewBox="0 0 16 16"
      >
        <path d="M8.5 11.5a.5.5 0 0 1-1 0V7.707L6.354 8.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 7.707z" />
        <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
      </svg>
      Upload image
      <input required type="file" hidden onChange={handleFileChange} />
    </label>
  );
}

// AddProduct component
function AddProduct() {
  const [category, setCategory] = useState(0);
  const [categories, setCategories] = useState([]); // Default to empty array
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [categoriesru, setCategoriesru] = useState([]); // Default to empty array
  const [titleru, setTitleru] = useState("");
  const [descriptionru, setDescriptionru] = useState("");
  const [properties, setProperties] = useState([
    { logo: "", text: "", logoRu: "", textRu: "" },
  ]); // Default to an array with one object
  const [images, setImages] = useState([null, null, null]); // Store the actual file objects
  const [coverImageId, setCoverImageId] = useState(null);
  const [newCategoryName, setNewCategoryName] = useState(""); // Новая категория
  const [newCategoryNameru, setNewCategoryNameru] = useState(""); // Новая категория
  const [categoryNameAz, setCategoryNameAz] = useState("");
  const [categoryNameRu, setCategoryNameRu] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const token = Cookies.get("token_adminPage");

  useEffect(() => {
    // Fetch categories from API with token in headers
    axios
      .get(`${Base_url}az/categories/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => setCategories(response.data.categories || [])) // Ensure categories is an array
      .catch((error) => console.error("Error fetching categories", error));
  }, [token]);
  useEffect(() => {
    // Fetch categories from API with token in headers
    axios
      .get(`${Base_url}ru/categories/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => setCategoriesru(response.data.categories || []))
      .catch((error) => console.error("Error fetching categories", error));
  }, [token]);
  const addPropertyItem = () => {
    setProperties([
      ...properties,
      { logo: "", text: "", logoRu: "", textRu: "" },
    ]);
  };
  const handleRemoveProducts = (index) => {
    const updatedProducts = properties.filter((_, i) => i !== index);
    setProperties(updatedProducts);
  };
  const handlePropertyChange = (index, key, value) => {
    const updatedProperties = [...properties];
    updatedProperties[index][key] = value;
    setProperties(updatedProperties);
  };

  const handleImageUpload = (file, index) => {
    const updatedImages = [...images];
    updatedImages[index] = file;
    setImages(updatedImages);
  };

  // Create new category
  const handleCreateCategory = async (e) => {
    e.preventDefault();
    if (!newCategoryName.trim() || !newCategoryNameru.trim()) {
      return alert("Yeni kateqoriya adlarını daxil edin.");
    }
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await axios.post(
        `${Base_url}admin/categories/create`,
        {
          name: newCategoryName,
          translations: {
            ru: {
              name: newCategoryNameru, // Russian category name
            },
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      window.location.reload();
    
      setNewCategoryName(""); // Очистить поле ввода
      setNewCategoryNameru("");
    } catch (error) {
      
    }
  };

  // Delete category
  const handleDeleteCategory = async (categorySlug) => {
    try {
      await axios.delete(`${Base_url}admin/categories/${categorySlug}/delete`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    
      setCategories(categories.filter((cat) => cat.slug !== categorySlug)); // Удалить категорию из списка
    } catch (error) {
     
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation
    if (!title) {
      return alert("Пожалуйста, введите название продукта.");
    }
    if (!description) {
      return alert("Пожалуйста, введите описание продукта.");
    }
    if (category === 0) {
      return alert("Пожалуйста, выберите категорию.");
    }
    if (coverImageId === null) {
      return alert("Пожалуйста, выберите обложку (cover image).");
    }
    if (images.every((image) => image === null)) {
      return alert("Пожалуйста, загрузите хотя бы одно изображение.");
    }

    const emptyProperty = properties?.find(
      (property) => !property.logo || !property.text
    );
    if (emptyProperty) {
      return alert("Пожалуйста, заполните все свойства (логотип и текст).");
    }

    // Form data submission
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("translations[ru][title]", titleru);
    formData.append("translations[ru][description]", descriptionru);
    formData.append("category_id", category);
    formData.append("cover_image_id", coverImageId);

    properties?.forEach((property, index) => {
      formData.append(`properties[${index}][logo]`, property.logo);
      formData.append(`properties[${index}][text]`, property.text);
      formData.append(
        `properties[${index}][translations][ru][logo]`,
        property.logoRu
      );
      formData.append(
        `properties[${index}][translations][ru][text]`,
        property.textRu
      );
    });

    images.forEach((image, index) => {
      if (image) {
        formData.append(`images[${index}]`, image);
      }
    });

    try {
      // eslint-disable-next-line no-unused-vars
      const response = await axios.post(
        `${Base_url}admin/services/create`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      window.location.reload();
    } catch (error) {
     
    }
  };
  const openUpdateModal = (category) => {
    setCategoryNameAz(category.name);
    setCategoryNameRu(
      categoriesru.find((e) => e.slug === category.slug)?.name || ""
    );
    setSelectedCategoryId(category.slug);

    setIsModalOpen(true);
  };
  const handleUpdateCategory = async () => {
    if (!categoryNameAz.trim() || !categoryNameRu.trim()) {
      return alert("Yeni kateqoriya adlarını daxil edin.");
    }
   
    try {
      await axios.post(
        `${Base_url}admin/categories/${selectedCategoryId}/update`,
        {
          name: categoryNameAz,
          translations: {
            ru: {
              name: categoryNameRu,
            },
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsModalOpen(false);
      window.location.reload(); // Reload page after update
    } catch (error) {
     
    }
  };
  return (
    <>
      <form className="right-content add-product" onSubmit={handleSubmit}>
        <div className="box container-1">
          <div className="top">
            <div className="w-40">
              <label className="select-box">
                <input type="number" hidden />
                {/* <div className='select' style={{ color: category === 0 ? '#A0A0A0' : '#151515' }}>
                {category === 0 ? 'Kateqoriya seçin' : categories.find(item => item.id === category)?.name}
              </div> */}
                <div
                  className="select"
                  style={{ color: category === 0 ? "#A0A0A0" : "#151515" }}
                >
                  {category === 0
                    ? "Kateqoriya seçin"
                    : categories.find((item) => item.id === category)?.name ||
                      "Категория не найдена"}
                </div>

                <ul className="select-items">
                  {categories.map((item) => (
                    <li
                      key={item.id}
                      className="select-item"
                      onClick={() => setCategory(item.id)}
                      required
                    >
                      {item.name}
                      {/* Добавляем кнопку для удаления категории */}
                      <div className="categoryButtonsicon">
                        <button
                          type="button"
                          onClick={() => openUpdateModal(item)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                            style={{ cursor: "pointer" }}
                          >
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path
                              fillRule="evenodd"
                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                            />
                          </svg>
                        </button>
                        {/* Delete category button */}
                        <button
                          className="deletebutton"
                          type="button"
                          onClick={() => handleDeleteCategory(item.slug)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-trash3"
                            viewBox="0 0 16 16"
                            style={{ cursor: "pointer" }}
                          >
                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                          </svg>
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>
              </label>

              {/* Поле для добавления новой категории */}
              <div>
                <label className="form-item">
                  Yeni kateqoriya yaradın [az]
                  <input 
                    placeholder="Kateqoriya adını daxil edin"
                    value={newCategoryName}
                    onChange={(e) => setNewCategoryName(e.target.value)}
                  />
                </label>
                <label className="form-item">
                  Yeni kateqoriya yaradın [ru]
                  <input
                    placeholder="Kateqoriya adını daxil edin"
                    value={newCategoryNameru}
                    onChange={(e) => setNewCategoryNameru(e.target.value)}
                  />
                </label>
                <button
                  className="btn"
                  type="button"
                  onClick={handleCreateCategory}
                >
                  Kateqoriya yarat
                </button>
              </div>

              <label className="form-item">
                Məhsulun adı[az]
                <input
                  required
                  placeholder="Мəhsulu daxil edin"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </label>
              <label className="form-item">
                Məhsulun adı[ru]
                <input
                  required
                  placeholder="Мəhsulu daxil edin"
                  value={titleru}
                  onChange={(e) => setTitleru(e.target.value)}
                />
              </label>
            </div>
          </div>
          <div className="top">
            <div className="w-40">
              <label className="form-item">
                Başlıq[az]
                <input
                  required
                  placeholder="Başlıq daxil edin"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </label>
            </div>
            <div className="w-40">
              <label className="form-item">
                Başlıq[ru]
                <input
                  required
                  placeholder="Başlıq daxil edin"
                  value={descriptionru}
                  onChange={(e) => setDescriptionru(e.target.value)}
                />
              </label>
            </div>
            <div className="w-60">
              <h4>Məhsulun şəkilləri</h4>
              <div className="add-img-box">
                {images.map((img, index) => (
                  <div key={index} className="image-item">
                    <input
                      type="radio"
                      name="cover-image"
                      required
                      onChange={() => setCoverImageId(index)} // Set the index as cover image ID
                      checked={coverImageId === index}
                    />
                    <AddImg onUpload={handleImageUpload} index={index} />
                    {img && (
                      <div className="imageUrl-radio">
                        <p>{img.name}</p> {/* Display the image file name */}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="bottom">
            {properties?.map((property, index) => (
              <>
                <div className="form-box" key={index}>
                  <label className="form-item">
                    Başlıq[az]
                    <input
                      type="text"
                      required
                      placeholder="Logo"
                      value={property.logo}
                      onChange={(e) =>
                        handlePropertyChange(index, "logo", e.target.value)
                      }
                    />
                  </label>
                  <label className="form-item">
                    Dəyər[az]
                    <input
                      type="text"
                      required
                      placeholder="Text"
                      value={property.text}
                      onChange={(e) =>
                        handlePropertyChange(index, "text", e.target.value)
                      }
                    />
                  </label>
                </div>
                <div className="form-box" key={index}>
                  <label className="form-item">
                    Başlıq[ru]
                    <input
                      type="text"
                      required
                      placeholder="Logo"
                      value={property.logoRu}
                      onChange={(e) =>
                        handlePropertyChange(index, "logoRu", e.target.value)
                      }
                    />
                  </label>
                  <label className="form-item">
                    Dəyər[ru]
                    <input
                      type="text"
                      required
                      placeholder="Text"
                      value={property.textRu}
                      onChange={(e) =>
                        handlePropertyChange(index, "textRu", e.target.value)
                      }
                    />
                  </label>
                </div>
                <button
                  className="btn-red"
                  type="button"
                  onClick={() => handleRemoveProducts(index)}
                >
                  Kateqoriyanı silin
                </button>
              </>
            ))}
            <button className="btn" type="button" onClick={addPropertyItem}>
              Setir əlavə edin
            </button>
          </div>
        </div>
        <button type="submit" className="btn">
          Yadda saxlayın
        </button>
      </form>
      <Modal
        show={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        categoryNameAz={categoryNameAz}
        setCategoryNameAz={setCategoryNameAz}
        categoryNameRu={categoryNameRu}
        setCategoryNameRu={setCategoryNameRu}
        handleUpdateCategory={handleUpdateCategory}
        // categoryRu= {}
      />
    </>
  );
}

export default AddProduct;
