import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom'
import Languages from './Languages';

function MediaMenu({ showMenu, setShowMenu }) {

    const [showMedaiLangs, setShowMedaiLangs] = useState(false)
    const { t } = useTranslation();
    useEffect(() => {
        if (!showMenu) {
            setShowMedaiLangs(false);
        }
    }, [showMenu]);

    return (
        <div className={`mobile-menu ${showMenu ? 'mobile-menu-activ' : ''}`}>
            <ul className='mobile-swip-item'>
                {!showMedaiLangs &&
                    (<>
                        <li><NavLink onClick={() => setShowMenu(false)} to='/' className='nav-link'>  {t('anaseyfe')}</NavLink></li>
                        <li><NavLink onClick={() => setShowMenu(false)} to='/services' className='nav-link'>{t("XIDMƏTLƏR")}</NavLink></li>
                        <li><NavLink onClick={() => setShowMenu(false)} to='/activity' className='nav-link'>{t("FƏALIYYƏT")}</NavLink></li>
                        <li><NavLink onClick={() => setShowMenu(false)} to='/about' className='nav-link'>{t("HAQQIMIZDA")}</NavLink></li>
                        <Languages></Languages>
            
                    </>) 
                    
            
                    }
        </ul>
        </div >
    )
}

export default MediaMenu